import * as EnvDictionary from "utils/dictionary/env";
import * as Configuration from 'utils/configuration/tenants';
import * as utils from 'utils/api/common/invocationUtils';

const baseUrl = `${EnvDictionary.REACT_APP_PROVISIONING_MANAGER_API_BASE_URL}/${Configuration.tenants}`;

export async function getTenantsForUser(userId, accessToken) {
    // Check local storage first, if not exists, make api call
    let tenants = JSON.parse(sessionStorage.getItem(Configuration.TENANT_STORAGE_KEY));
    if (tenants) {
        return tenants;
    }

    const { data } = await utils.getResource(
            `${baseUrl}/${userId}?api-version=${Configuration.apiVersion}`, 
            accessToken
        );

    if (data) {
        sessionStorage.setItem(Configuration.TENANT_STORAGE_KEY, JSON.stringify(data));
    }
    return data;
}