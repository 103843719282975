import React from 'react';

import PlatformPage from 'pages/platformPage/platformPage';

import Profile from './profile';

/**
 * Routes needed to provide My Profile access.
 */
export const ProfileRoutes = () => {
  return (
    <PlatformPage>
      <Profile />
    </PlatformPage>
  );
};

export default ProfileRoutes;
