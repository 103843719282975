import * as utils from 'utils/api/common/invocationUtils';
import * as configuration from 'utils/configuration/subscriptions';
import { API_VERSION_QUERY_KEY } from 'utils/dictionary';
import * as EnvDictionary from 'utils/dictionary/env';

const baseUrl = `${EnvDictionary.REACT_APP_PROVISIONING_MANAGER_API_BASE_URL}/${configuration.SUBSCRIPTIONS_ROOT}`;
const baseQueryString = `?${API_VERSION_QUERY_KEY}=${configuration.SUBSCRIPTIONS_API_VERSION}`;

/**
 * Retrieve a listing of all subscriptions that the access token has acccess to and that contain
 * the requested application.
 *
 * applicationId - the requested application
 * accessToken - the jwt/access token to use
 */
export async function getUserSubscriptions(applicationId, accessToken) {
  console.count('getUserSubscriptions');

  let response = await utils.getResource(
    `${baseUrl}${baseQueryString}&clientId=${applicationId}`,
    accessToken,
  );

  console.group('getUserSubscriptions');
  //console.log(JSON.stringify(response));
  console.groupEnd('getUserSubscriptions');

  return response;
}

/**
 * Retrieve all of subscriptions for the user represented by the accessToken.
 */
export async function getAllUserSubscriptions(accessToken) {
  //console.count('getAllUserSubscriptions');

  let response = await utils.getResource(`${baseUrl}/all${baseQueryString}`, accessToken);

  //console.group('getAllUserSubscriptions');
  //console.log(JSON.stringify(response));
  //console.groupEnd('getAllUserSubscriptions');

  return response;
}
