import React from 'react';
import Service from 'components/service/service';
import { List } from '@mui/material';

const ServiceList = ({services}) => {
    if (services.length > 0) {
        return (
            <List dense>
                {services.map((s, i) => {
                    return (<Service 
                        key={i} 
                        icon={s.icon} 
                        name={s.serviceName} 
                        description={s.description} 
                        link={s.link}
                        internal={s.internal}
                        tooltip={s.tooltip}
                    />);
                })}
            </List>
        );
    }
    return null;
}

export default ServiceList;