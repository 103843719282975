import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppCard from 'components/appCard/appCard';
import { Grid, Container } from '@mui/material';
import UnauthorizedApplicationAccess from 'components/unauthorizedApplicationAccess/unauthorizedApplicationAccess';
import { UserInfoContext } from 'contexts/userInfoContext/userInfoContext';

const OrganizationPicker = ({ subscriptions, icon, url, description, name, onItemClick }) => {
  let userInfo = useContext(UserInfoContext) ?? {};
  return (
    <>
      {subscriptions.length > 0 ? (
        <Container style={{ marginBottom: '5em', flex: '1 1 auto', zIndex: '900' }} maxWidth="xl">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            wrap="wrap"
            style={{ margin: '15px 0 0 0' }}
          >
            <Grid item xs={5} />
            <Grid item xs={7} style={{ flex: '1 1 auto', marginTop: '50px' }}>
              <AppCard
                name={name}
                description={description}
                url={url}
                subscriptions={subscriptions ?? []}
                subscriptionClick={onItemClick}
                iconLink={icon}
                iconAlt={name}
              />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <>
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <UnauthorizedApplicationAccess email={userInfo.email} application={name} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

OrganizationPicker.propTypes = {
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      organizationId: PropTypes.string,
      organizationName: PropTypes.string,
    }),
  ),
  onItemClick: PropTypes.func.isRequired,
};

OrganizationPicker.defaultProps = {
  subscriptions: [],
};

export default OrganizationPicker;
