import React, { createContext } from 'react';
import { useGetTenantsForUser } from 'utils/hooks/useGetTenantsForUser/useGetTenantsForUser';

const TenantInfoContext = createContext();

const TenantInfoProvider = ({ children }) => {
  const tenantInfo = useGetTenantsForUser();

  return <TenantInfoContext.Provider value={tenantInfo}>{children}</TenantInfoContext.Provider>;
};

export { TenantInfoContext, TenantInfoProvider };