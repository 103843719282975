import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton, Tooltip } from '@mui/material';

import { InfoIcon } from 'components/icons/icons';

const StyledIconButton = styled(IconButton)`
  margin-left: 0.75em;
`;

const StyledInfoIcon = styled(InfoIcon)`
  color: ${props => props.theme.colors.neutralGrey};
`;

const ToolTip = ({ innerTitle, ...props }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipToggle = () => {
    setOpen(!open);
  };

  return (
    <Tooltip
      title={innerTitle}
      disableHoverListener
      disableFocusListener
      open={open}
      onClose={handleTooltipClose}
      {...props}
    >
      <StyledIconButton
        variant="contained"
        color="primary"
        size="small"
        onClick={handleTooltipToggle}
      >
        <StyledInfoIcon fontSize="medium" />
      </StyledIconButton>
    </Tooltip>
  );
};

ToolTip.propTypes = {
  innerTitle: PropTypes.string.isRequired,
};

export default ToolTip;
