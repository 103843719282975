import { createContext, useContext, useState, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const Context = createContext(null);

export const Auth0DataProvider = props => {
  const { audience, clientId, domain, myHealthwiseBaseUrl, filterRoutes, children } = props;
  const { isAuthenticated } = useAuth0();

  const [auth0Data, setAuth0Data] = useState();
  const [organizationAlias, setOrganizationAlias] = useState();

  const getOrgId = async alias => {
    // If there is an organization name in the url, fetch the organization id for it
    var requestHeaders = {
      Accept: 'application/json',
    };

    var request = new Request(`${myHealthwiseBaseUrl}/organization?organizationName=${alias}`, {
      method: 'get',
      headers: new Headers(requestHeaders),
    });
    var response = await fetch(request);
    const data = await response.json();
    if (!data.organizationId) {
      // throw some error here!
    }

    return data.organizationId;
  };

  const configureAuth0 = organizationId => {
    const params = {
      audience: audience,
      redirect_uri: window.location.origin,
    };

    if (organizationId) {
      params.organization = organizationId;
    }

    const config = {
      domain: domain,
      clientId: clientId,
      authorizationParams: params,
    };

    return config;
  };

  useEffect(() => {
    // Attempt to grab alias from url (https://baseurl/orgAlias/stuff)
    let alias = window.location.pathname
      .split('/')
      .filter(o => o.length) // Remove empty elements
      .filter(r => !filterRoutes.includes(r))[0]; // Remove known application routes

    /** 
       * If there isn't an organization alias in the route, check the query string parameter.
       * The querystring is used for a signout. This will ensure the user is not asked to
       * enter an Auth0 Organization Name after signing out.
       */ 
    if (!alias) {
      const queryParams = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      alias = queryParams.orgAlias;
    }

    if (!isAuthenticated) {
      // If the alias is in the url
      if (alias) {
        // Use an iffy to ensure we resolve all the appropriate async calls
        (async () => {
          const organizationId = await getOrgId(alias);
          const config = configureAuth0(organizationId);
          setAuth0Data({
            auth0Config: config,
              organizationAlias: null, // Set after authentication happens
            isLoading: false,
          });
        })();

        setOrganizationAlias(alias);
      } else {
        // If the alias is not in the url
        const config = configureAuth0();
        setAuth0Data({
          auth0Config: config,
          organizationAlias: null,
          isLoading: false,
        });
      }
    } else {
      auth0Data.organizationAlias = organizationAlias;
      setAuth0Data(auth0Data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return <Context.Provider value={auth0Data}>{children}</Context.Provider>;
};

export const useAuth0Data = () => useContext(Context);


export const PostLoginContext = createContext(null);

export const PostLoginRedirector = ({ setOrgAlias, filterRoutes, children }) => {
  const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [ state, setState ] = useState();
  
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const setToken = async () => {
      const token = await getAccessTokenSilently();
      const decodedToken = jwt_decode(token);
      let baseRoute = decodedToken.tempOrganizationAlias;

      setOrgAlias(baseRoute);
      setState({
        organizationAlias: baseRoute
      });

      // lastElement might be a url with params. EG - "unauthorized/:application/:email"
      // just using pop() would get rid of other parts of the route
      const lastElement = location.pathname.split('/')
        .filter(e => e.length) // first element is always "" after splitting
        .splice(1)
        .join('/');

      const additionalRoute = (
        filterRoutes.includes(lastElement)
        || filterRoutes.some(r => lastElement.includes(r)) // If there are query params in a route
      ) ? lastElement : null;

      if (additionalRoute) {
        baseRoute = `${baseRoute}/${additionalRoute}`;
      }

      navigate({
        pathname: baseRoute,
        search: location.search
      });
    };

    if (isAuthenticated) {
      setToken();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  return <PostLoginContext.Provider value={state}>{children}</PostLoginContext.Provider>;
};

export const usePostLoginContext = () => useContext(PostLoginContext);
export default PostLoginContext;