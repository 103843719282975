import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton, InputAdornment, Tooltip } from '@mui/material';

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

const StyledIconButton = styled(IconButton)`
  color: ${props => props.theme.colors.black};
  margin-right: ${props => props.theme.spacingScale[1]};
`;

// https://stackoverflow.com/a/72273054 for the crazyiness of the tooltip
const StyledTooltip = styled(props => <Tooltip classes={{ popper: props.className }} {...props} />)`
  & .MuiTooltip-tooltip {
    font-size: ${props => props.theme.typeScale[1]};
  }
`;

const AdornmentToolTip = ({ title, isVisible, ariaLabel, ...props }) => {
  return (
    <InputAdornment position="end">
      <StyledTooltip arrow title={title} aria-label={ariaLabel ? ariaLabel : null} {...props}>
        <StyledIconButton variant="contained" color="primary" size="small">
          {isVisible ? <Visibility /> : <VisibilityOff />}
        </StyledIconButton>
      </StyledTooltip>
    </InputAdornment>
  );
};

AdornmentToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default AdornmentToolTip;
