import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';
import { IconButton } from '@mui/material';
import { ArrowBackIcon } from 'components/icons/icons';

import { APPLICATION_DASHBOARD_PATH } from 'utils/dictionary/routes';
import { SIGNIN_REDIRECT_PATH } from 'utils/dictionary';

import styled from 'styled-components';
import OrganizationPicker from 'components/organizationPicker/organizationPicker';

import { Auth0State } from 'components/auth';

const StyledPrevPageButton = styled(IconButton)`
  font-size: ${props => props.theme.typeScale[2]};
  color: ${props => props.theme.colors.primary};
  margin-left: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  &:hover {
    background-color: ${props => props.theme.colors.lighterGrey};
  }
`;

const StyledDiv = styled.div`
  padding-top: 20px;
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  color: ${props => props.theme.colors.darkPrimary};
`;

const Subscriptions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authState = Auth0State();
  const [subscriptionToNavigateTo, setSubscription] = useState();
  useErrorHandler(authState?.error);

  useEffect(() => {
    if (authState?.isAuthenticated && subscriptionToNavigateTo) {
      const requestedScope =
        `${subscriptionToNavigateTo.organizationId}::${subscriptionToNavigateTo.id}`.toLowerCase();
      navigate(SIGNIN_REDIRECT_PATH, {
        state: {
          additionalScope: requestedScope,
        },
      });
    }
  }, [subscriptionToNavigateTo, navigate, authState]);

  // TODO Pull out to the dictionaries
  document.title = 'Select Organization - MyHealthwise';

  const goToSubscription = subscription => {
    if (subscriptionToNavigateTo !== subscription) {
      setSubscription(subscription);
    }
  };

  if (location?.state?.subscriptions?.length === 1) {
    // We only have one subscription, so let's just redirect
    goToSubscription(location.state.subscriptions[0]);
    return null;
  }

  const goToMyHealthwise = () => {
    // Use navigate to respect Router basename (organization name)
    navigate(APPLICATION_DASHBOARD_PATH);
  };

  return (
    <>
      <StyledDiv>
        <StyledPrevPageButton onClick={goToMyHealthwise}>
          <StyledArrowBackIcon />
          Back to MyHealthwise
        </StyledPrevPageButton>
      </StyledDiv>

      <OrganizationPicker
        name={location?.state?.name}
        icon={location?.state?.icon}
        url={location?.state?.url}
        description={location?.state?.description}
        subscriptions={location?.state?.subscriptions ?? []}
        onItemClick={goToSubscription}
      />
    </>
  );
};

export default Subscriptions;
