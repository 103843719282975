import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/errorMessage/errorMessage';
import { HOME_PATH } from 'utils/dictionary';

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }) => {
  return (
    <ErrorMessage errors={[error?.message]}>
      <Link onClick={() => resetErrorBoundary()} to={HOME_PATH}>
        Home
      </Link>
    </ErrorMessage>
  );
};

ErrorBoundaryFallback.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default ErrorBoundaryFallback;
