import { createGlobalStyle } from 'styled-components';

const colors = {
  red: '#dd372f',
  darkRed: '#a03123',
  yellow: '#ffb71b',
  orange: '#ff6c37',
  green: '#00a887',
  white: '#ffffff',
  lightPurple: 'e0c7e6',
  purple: '#6c3175',
  lightBlue: '#63cfe3',
  blue: '#00a6ce',
  teal: '#309a99',
  lighterGrey: '#f4f4f4',
  lightGrey: '#d8d8d8',
  neutralGrey: '#727272',
  darkGrey: '#676767',
  darkestGrey: '#424242',
  black: '#262626',
  lightPrimary: '#aae2d0',
  primary: '#0d8484',
  darkPrimary: '#006272',
  skylight: '#00a2c7',
  stormy: '#003a47',
  // Used for shadow effects, note transparency value
  shadow: '#00000014',
  // Same as light grey, eqiv. 6HEX is e0e0e0
  opaqueLightGrey: '#d8d8d880',
};

// sync'ed with provisioning-tool-ui's spacingScale
//prettier-ignore
const spacingScale = {
  0: '0.25rem',   //  4px
  1: '0.5rem',    //  8px
  2: '0.75rem',   // 12px
  3: '1rem',      // 16px
  4: '1.5rem',    // 24px
  5: '2rem',      // 32px
  6: '3rem',      // 48px
  7: '4rem',      // 64px
  8: '6rem',      // 96px
  9: '8rem',      // 128px
  10: '12rem',    // 192px
  11: '16rem',    // 256px
  12: '24rem',    // 384px
  13: '32rem',    // 512px
  14: '40rem',    // 640px
  15: '48rem',    // 768px
};

//prettier-ignore
const typeScale = {
  0: '0.75rem',   // 12px
  1: '0.875rem',  // 14px
  2: '1rem',      // 16px
  3: '1.125rem',  // 18px
  4: '1.25rem',   // 20px
  5: '1.5rem',    // 24px
  7: '2.25rem',   // 36px
  8: '3rem',      // 48px
  9: '4rem',      // 128px
  13: '2rem',     // 32px
  17: '0.813rem', // 13.008px
};

// sync'ed with provisioning-tool-ui's headerScale
// Values that roughly match up with the h1..h6 font size progression
const headerScale = {
  1: typeScale[7],
  2: typeScale[5],
  3: typeScale[3],
  4: typeScale[2],
  5: typeScale[1],
  6: typeScale[0],
};

const tokens = {
  background: colors.lighterGrey,
  backgroundLight: colors.white,
  backgroundDark: colors.lightGrey,
  info: colors.darkPrimary,
  success: colors.lightPrimary,
  error: colors.red,
  warning: colors.yellow,
  primaryText: colors.darkestGrey,
  secondaryText: colors.neutralGrey,
  primaryTextOnDark: colors.white,
  disabledText: colors.lightGrey,
  accentText: colors.darkPrimary,
};

export const theme = {
  colors: {
    ...colors,
    ...tokens,
  },
  font: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;`,
  headerScale: {
    ...headerScale,
  },
  spacingScale: {
    ...spacingScale,
  },
  typeScale: {
    ...typeScale,
  },
};

export const GlobalStyle = createGlobalStyle`
  ${({ theme }) => `
  html, body{   
    height: 100%;
  }
  body {
    margin: 0;
    font-family: ${theme.font}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${theme.colors.white};
    color: ${theme.colors.primaryText};   
  }
  #root {
    height: 100%;
  }
 
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
`}
`;
