import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardContent, Typography, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';
import { PROFILE_PATH } from 'utils/dictionary';

const StyledCard = styled(Card)`
  text-align: center;
  display: inline-block;
  width: 500px;
  margin-right: ${props => props.theme.spacingScale[6]};
  margin-bottom: ${props => props.theme.spacingScale[6]};
`;

const StyledCardContent = styled(CardContent)`
  padding: 1.75em;
`;

const StyledLink = styled(Link)`
  &.MuiTypography-root {
    color: ${props => props.theme.colors.primary};
  }
`;

const StyledCardHeaderText = styled(Typography)`
  padding-bottom: 0.625rem;
  &.MuiTypography-root {
    font-weight: 600;
    color: ${props => props.theme.colors.darkestGrey};
    font-size: ${props => props.theme.typeScale[2]};
  }
`;

const StyledCardContentText = styled(Typography)`
  padding-bottom: ${props => props.theme.spacingScale[3]};
  line-height: ${props => props.theme.typeScale[4]};
  &.MuiTypography-root {
    color: ${props => props.theme.colors.neutralGrey};
    font-size: ${props => props.theme.typeScale[2]};
    padding-left: 10px;
  }
`;

const UnauthorizedApplicationAccess = ({ email, application }) => {
  return (
    <StyledCard elevation="0">
      <StyledCardContent>
        <StyledCardHeaderText component="h2">{email}</StyledCardHeaderText>
        <StyledCardContentText>
          You do not have permission to access <b>{application}</b>. If you believe this is a
          mistake, review your profile and contact your administrator.
        </StyledCardContentText>
        <StyledLink component={NavLink} to={PROFILE_PATH} underline="always">
          View Your Profile
        </StyledLink>
      </StyledCardContent>
    </StyledCard>
  );
};

UnauthorizedApplicationAccess.propTypes = {
  email: PropTypes.string.isRequired,
  application: PropTypes.string.isRequired,
};

export default UnauthorizedApplicationAccess;
