import React from 'react';
import AppCard from 'components/appCard/appCard';

const AppCardList = ({ applications, subscriptionClick }) => {
  if (applications.length > 0) {
    return (
      <div style={{ display: 'inline' }}>
        {applications.map((application, index) => (
          <AppCard
            key={index}
            name={application.name}
            description={application.description}
            url={application.fullyQualifiedName}
            subscriptions={application.subscriptions}
            subscriptionClick={subscriptionClick}
            iconLink={application.icon}
            iconAlt={`${application.name} icon`}
          />
        ))}
      </div>
    );
  }

  return null;
};

export default AppCardList;
