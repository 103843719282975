import React, { useState } from 'react';
import Input from '@mui/material/Input';
import { Button as MaterialButton } from '@mui/material';
import { changePassword } from 'utils/api/profile';
import { changePasswordUrl } from 'utils/dictionary/profile';
import ConfirmationModal from 'components/confirmationModal/confirmationModal';
import { Check, Close } from '@mui/icons-material';
import { InputLabel } from '@mui/material';
import styled from 'styled-components';
import AdornmentToolTip from 'components/tooltip/adornment-tooltip';

import { Auth0State, Auth0Jwt } from 'components/auth';
import {
  isCorrectLength,
  containsNumber,
  containsUppercase,
  containsLowercase,
  doesNotContainUsername,
  doPasswordsMatch,
  newPasswordMatchesOldPassword,
  hasOldPasswordValue,
} from './passwordValidation';

const CheckIcon = styled(Check)`
  font-size: ${props => props.theme.typeScale[4]};
  color: ${props => props.theme.colors.primary};
  vertical-align: middle;
`;

const CloseIcon = styled(Close)`
  font-size: ${props => props.theme.typeScale[4]};
  vertical-align: middle;
`;

const StyledInputLabel = styled(InputLabel)`
  font-size: 11px;
  margin-bottom: 3px;
`;

const StyledButton = styled(MaterialButton)`
  border: none;
  background-color: ${props => props.theme.colors.lighterGrey};
  color: ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.typeScale[18]};
  text-transform: none;
`;

const ErrorSpan = styled.span`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.typeScale[0]};
`;

const ChangeModal = props => {
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
    confirmNewPassword: '',
    showConfirmNewPassword: false,
    oldPassword: '',
    showOldPassword: false,
  });

  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isPasswordError, setIsPasswordError] = React.useState(false);
  const [isMatchingError, setIsMatchingError] = React.useState(false);
  const auth0State = Auth0State();
  const accessToken = Auth0Jwt();

  const minPasswordLength = 8;
  let isInputMatchingError = false;
  let isDisabled = true;

  let getDoesNotContainUsername = () => {
    // TODO: Not sure if this will remain 'name' - seems like this might change from an auth0 POV.
    // Also, I suspect we should be using the user context for this instead.
    const email = auth0State?.user?.name;
    if (email) {
      return doesNotContainUsername(values.password, email);
    } else {
      return false;
    }
  };

  let getDoPasswordsMatch = () => {
    var result = doPasswordsMatch(values.password, values.confirmNewPassword);
    isInputMatchingError = !result;
    return result;
  };

  let getNewPasswordMatchesOldPassword = () => {
    var result = newPasswordMatchesOldPassword(
      values.oldPassword,
      values.password,
      values.confirmNewPassword,
    );
    return result;
  };

  // eslint-disable-next-line no-unused-vars
  let getOldPasswordHasValue = () => {
    var result = hasOldPasswordValue(values.oldPassword);
    return result;
  };

  const setDisabled = () => {
    if (
      getOldPasswordHasValue() &&
      !getNewPasswordMatchesOldPassword() &&
      getDoPasswordsMatch() &&
      containsLowercase(values.password) &&
      containsUppercase(values.password) &&
      containsNumber(values.password) &&
      getDoesNotContainUsername() &&
      isCorrectLength(values.password, minPasswordLength)
    ) {
      isDisabled = false;
    } else {
      isDisabled = true;
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfirmNewPassword = () => {
    setValues({ ...values, showConfirmNewPassword: !values.showConfirmNewPassword });
  };

  const handleClickShowOldPassword = () => {
    setValues({ ...values, showOldPassword: !values.showOldPassword });
  };

  const handlePasswordChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const { handleModalClose, modalOpen } = props;

  const ulStyle = {
    listStyleType: 'none',
    paddingInlineStart: 0,
  };

  const inputStyle = {
    backgroundColor: '#ebebeb',
    borderRadius: '10px 10px 0 0',
    width: '100%',
    marginBottom: '15px',
    padding: '.225em 0 .225em 1em',
  };

  const errorInputStyle = {
    backgroundColor: '#ebebeb',
    borderRadius: '10px 10px 0 0',
    width: '100%',
    padding: '.225em 0 .225em 1em',
  };

  const validItemStyle = {
    color: '#0d8484',
    verticalAlign: 'middle',
  };

  const itemStyle = {
    verticalAlign: 'middle',
  };

  const clearErrors = () => {
    setIsSubmitting(false);
    setIsError(false);
    setIsPasswordError(false);
    setIsMatchingError(false);
  };

  const closeModal = () => {
    setValues({
      ...values,
      oldPassword: '',
      password: '',
      confirmNewPassword: '',
      showConfirmNewPassword: false,
      showOldPassword: false,
      showPassword: false,
    });
    handleModalClose();
    clearErrors();
  };

  const handleChangePasswordAction = async () => {
    setIsSubmitting(true);

    if (
      values.oldPassword === values.password ||
      values.oldPassword === values.confirmNewPassword
    ) {
      setIsMatchingError(true);
      setIsPasswordError(false);
      setIsSubmitting(false);
      isDisabled = true;
      return;
    }

    const requestBody = {
      oldPassword: values.oldPassword,
      newPassword: values.confirmNewPassword,
    };
    const { response } = await changePassword(changePasswordUrl, requestBody, accessToken);
    if (response.ok) {
      setIsSubmitting(false);
      closeModal();
      return;
    }
    if (response.status === 403) {
      setIsPasswordError(true);
      setIsError(false);
      setIsSubmitting(false);
      setMessage('Old password is incorrect');
      return;
    } else {
      setIsError(true);
      setIsPasswordError(false);
      setIsSubmitting(false);
      setMessage('Error updating password');
    }
  };

  const screenReadRequirements = () => {
    var errors = ['Confirm new password input. New Password input does not meet requirements: '];
    if (!getDoPasswordsMatch()) {
      errors.push('New passwords do not match. ');
    }
    if (getNewPasswordMatchesOldPassword) {
      errors.push('New password cannot be the same as your current password.');
    }
    if (!containsLowercase(values.password)) {
      errors.push('Password does not contain a lowercase letter. ');
    }
    if (!containsUppercase(values.password)) {
      errors.push('Password does not contain an uppercase letter. ');
    }
    if (!containsNumber(values.password)) {
      errors.push('Password does not contain a number. ');
    }
    if (!getDoesNotContainUsername()) {
      errors.push('Password cannot contain parts of your email. ');
    }
    if (!isCorrectLength(values.password, minPasswordLength)) {
      errors.push('Password does not contain at least 8 characters. ');
    }
    return errors;
  };

  const ChangeText = () => {
    const props = { values };
    return (
      <>
        <span>Your new password must meet the password policy requirements for this account:</span>
        <ul style={ulStyle}>
          <li>
            {isCorrectLength(values.password, minPasswordLength) ? (
              <CheckIcon data-testid="check1"></CheckIcon>
            ) : (
              <CloseIcon data-testid="x1"></CloseIcon>
            )}
            <span
              aria-hidden="true"
              style={
                isCorrectLength(values.password, minPasswordLength) ? validItemStyle : itemStyle
              }
            >
              {' '}
              At least 8 characters
            </span>
          </li>
          <li>
            {containsNumber(values.password) ? (
              <CheckIcon data-testid="check2"></CheckIcon>
            ) : (
              <CloseIcon data-testid="x2"></CloseIcon>
            )}
            <span
              aria-hidden="true"
              style={containsNumber(values.password) ? validItemStyle : itemStyle}
            >
              {' '}
              At least 1 number
            </span>
          </li>
          <li>
            {containsLowercase(values.password) ? (
              <CheckIcon data-testid="check3"></CheckIcon>
            ) : (
              <CloseIcon data-testid="x3"></CloseIcon>
            )}
            <span
              aria-hidden="true"
              style={containsLowercase(values.password) ? validItemStyle : itemStyle}
            >
              {' '}
              A lowercase letter
            </span>
          </li>
          <li>
            {containsUppercase(values.password) ? (
              <CheckIcon data-testid="check4"></CheckIcon>
            ) : (
              <CloseIcon data-testid="x4"></CloseIcon>
            )}
            <span
              aria-hidden="true"
              style={containsUppercase(values.password) ? validItemStyle : itemStyle}
            >
              {' '}
              An uppercase letter
            </span>
          </li>
          <li>
            {values.password.length > 0 && getDoesNotContainUsername() ? (
              <CheckIcon data-testid="check5"></CheckIcon>
            ) : (
              <CloseIcon data-testid="x5"></CloseIcon>
            )}
            <span
              aria-hidden="true"
              style={
                values.password.length > 0 && getDoesNotContainUsername()
                  ? validItemStyle
                  : itemStyle
              }
            >
              {' '}
              No parts of your email
            </span>
          </li>
        </ul>
        <div>
          <StyledInputLabel aria-label="Current password" htmlFor="current-password">
            Current Password
          </StyledInputLabel>
          <Input
            id="current-password"
            type={values.showOldPassword ? 'text' : 'password'}
            onChange={handlePasswordChange('oldPassword')}
            value={props.values.oldPassword}
            style={isPasswordError ? errorInputStyle : inputStyle}
            error={isPasswordError}
            endAdornment={
              <AdornmentToolTip
                onClick={handleClickShowOldPassword}
                title={values.showOldPassword ? 'Hide Current Password' : 'Show Current Password'}
                isVisible={values.showOldPassword}
                ariaLabel={
                  values.showOldPassword
                    ? 'Password is currently visible'
                    : 'Password is currently hidden'
                }
              ></AdornmentToolTip>
            }
          />
          {isPasswordError ? <ErrorSpan>Incorrect Password.</ErrorSpan> : null}
          <br></br>
          <StyledInputLabel
            aria-label={
              'New password input. New Password must contain a number, a lowercase letter, an uppercase letter, no parts of your email, and be at least 8 characters long'
            }
            htmlFor="new-password"
          >
            New Password
          </StyledInputLabel>
          <Input
            id="new-password"
            aria-invalid={isMatchingError ? true : null}
            type={values.showPassword ? 'text' : 'password'}
            onChange={handlePasswordChange('password')}
            value={props.values.password}
            style={isMatchingError ? errorInputStyle : inputStyle}
            onKeyUp={setDisabled()}
            error={isMatchingError}
            endAdornment={
              <AdornmentToolTip
                onClick={handleClickShowPassword}
                title={values.showPassword ? 'Hide New Password' : 'Show New Password'}
                isVisible={values.showPassword}
                ariaLabel={
                  values.showPassword
                    ? 'Password is currently visible'
                    : 'Password is currently hidden'
                }
              ></AdornmentToolTip>
            }
          />
          <br></br>
          {isMatchingError ? (
            <ErrorSpan>New password cannot be the same as current password</ErrorSpan>
          ) : null}
          <StyledInputLabel
            aria-label={isDisabled ? screenReadRequirements() : 'Confirm New Password Input'}
            htmlFor="confirm-password"
          >
            Confirm New Password
          </StyledInputLabel>
          <Input
            id="confirm-password"
            aria-invalid={isInputMatchingError ? true : null}
            type={values.showConfirmNewPassword ? 'text' : 'password'}
            onChange={handlePasswordChange('confirmNewPassword')}
            style={isInputMatchingError ? errorInputStyle : inputStyle}
            onKeyUp={setDisabled()}
            value={props.values.confirmNewPassword}
            error={isInputMatchingError}
            endAdornment={
              <AdornmentToolTip
                onClick={handleClickShowConfirmNewPassword}
                title={
                  values.showConfirmNewPassword
                    ? 'Hide Confirmed Password'
                    : 'Show Confirmed Password'
                }
                isVisible={values.showConfirmNewPassword}
                ariaLabel={
                  values.showConfirmNewPassword
                    ? 'Password is currently visible'
                    : 'Password is currently hidden'
                }
              ></AdornmentToolTip>
            }
          />
          {isInputMatchingError ? <ErrorSpan>New passwords do not match.</ErrorSpan> : null}
          <br></br>
        </div>
      </>
    );
  };

  return (
    <ConfirmationModal
      open={modalOpen}
      handleClose={closeModal}
      handleCancel={closeModal}
      handleConfirm={handleChangePasswordAction}
      title="Change Password"
      text={ChangeText()}
      confirmText="Change Password"
      isDisabled={isDisabled}
      isError={isError}
      isSubmitting={isSubmitting}
      message={message}
    />
  );
};

export const ChangePasswordModal = props => {
  const { profile } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = e => {
    e.preventDefault();
    setModalOpen(true);
  };
  const handleModalClose = e => {
    setModalOpen(false);
  };

  return (
    <>
      <ChangeModal profile={profile} modalOpen={modalOpen} handleModalClose={handleModalClose} />
      <StyledButton onClick={handleModalOpen}>Change Password</StyledButton>
    </>
  );
};
