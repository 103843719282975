import React from 'react';
import { Box, Grid, Card } from '@mui/material';
import styled from 'styled-components';

import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { LogoIcon } from 'components/icons/icons';

/**
 * Render a Material Card with the Healthwise Logo, a spinner, and a message.
 */

const StyledText = styled.p`
  text-align: center;
`;

const ProgressDisplayCard = styled(Card)`
  border: 1px solid ${props => props.theme.colors.lightGrey};
  text-align: center;
  display: inline-block;
  margin: ${props => props.theme.spacingScale[6]};
  box-shadow: 0px 3px 6px ${props => props.theme.colors.shadow};
  &.MuiPaper-rounded {
    border-radius: ${props => props.theme.spacingScale[2]};
    width: 275px;
    height: 310px;
  }
  &:hover {
    background-color: ${props => props.theme.colors.lighterGrey};
    border: 1px solid ${props => props.theme.colors.primary};
  }
`;

const ProgressDisplayCardContents = styled(Grid)`
  height: 100%;
  padding: ${props => props.theme.spacingScale[3]};
`;

const ProgressDisplay = props => {
  const { message } = props;
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <ProgressDisplayCard>
        <ProgressDisplayCardContents
          container
          direction="column"
          alignItems="stretch"
          justifyContent="space-between"
        >
          <Grid item>
            <LogoIcon />
          </Grid>
          <Grid item>
            <ProgressIndicator />
          </Grid>
          <Grid item>
            <StyledText>{message}</StyledText>
          </Grid>
        </ProgressDisplayCardContents>
      </ProgressDisplayCard>
    </Box>
  );
};

export default ProgressDisplay;
