import React, { useState, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';

import { Card, CardHeader, CardContent, TextField, InputLabel, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { WhiteButton, PrimaryButton } from 'components/buttons/button';
import { EditIcon } from 'components/icons/icons';
import Notification from 'components/notification/notification';

import { ChangePasswordModal } from './changePasswordModal';

import { modifyUserProfile } from 'utils/api/profile';

import { Auth0State, Auth0Jwt } from 'components/auth';

const StyledCard = styled(Card)`
  border-radius: 8px 8px 8px 8px;
`;

const StyledCardContent = styled(CardContent)`
  margin: 10px;
  &.MuiCardContent-root:last-child {
    padding-bottom: 12px;
  }
`;

const InfoCardHeader = styled(CardHeader)`
  border-radius: 8px 8px 0px 0px;
  height: ${props => props.theme.spacingScale[5]};
  background-color: ${props => props.theme.colors.background};
  padding-left: ${props => props.theme.spacingScale[4]};
  .MuiTypography-h5 {
    font-size: ${props => props.theme.typeScale[4]};
  }
`;

const EditIconButton = styled(IconButton)``;

const StyleDisabledTextField = styled(TextField)`
  height: 41px;
  .MuiInputBase-input {
    padding: 10px 0 5px 0px;
  }
  & .MuiInputBase-input.Mui-disabled {
    background-color: ${props => props.theme.colors.backgroundLight};
    color: ${props => props.theme.colors.primaryText};
    -webkit-text-fill-color: ${props => props.theme.colors.primaryText};
  }
  & .MuiInputBase-root.Mui-disabled:before {
    border-bottom-style: none;
  }
`;

const StyledLabel = styled(InputLabel)`
  font-size: ${props => props.theme.typeScale[0]};
  margin-top: 12px;
  padding-bottom: 8px;
  color: ${props => props.theme.colors.neutralGrey};
`;

const StyledTextField = styled(TextField)`
  height: 41px;
  padding: 0px;
  .MuiFilledInput-input {
    padding: 10px 0 5px 10px;
  }
`;

const StyledDivider = styled(Divider)`
  border: 1px ${props => props.theme.colors.lighterGrey};
  margin: 1.25rem 0 1.25rem 0;
  min-width: 200px;
  width: auto;
`;

const StyledEditButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
`;

const StyledResetPwdBtnContainer = styled.div`
  padding-top: 10px;
  height: 32px;
`;

const StyledCancelBtn = styled(WhiteButton)`
  text-transform: initial;
  &:hover {
    text-decoration: none;
    background-color: ${props => props.theme.colors.white};
  }
`;

const StyledSaveBtn = styled(PrimaryButton)`
  text-transform: initial;
  margin-left: 8px;

  &:hover {
    text-decoration: none;
    background-color: ${props => props.theme.colors.primary};
  }
`;

/**
 * @param {string} label - The text label of the field
 * @param {string} fieldName - This represents which part of the user's profile this InputText is modifying/displaying
 */
const FormikInputText = (props, formikprops) => {
  const { label, fieldName, isEdit } = props;

  return (
    <Field name={fieldName}>
      {({ field, form, meta }) => {
        return (
          <div>
            <StyledLabel id={fieldName + '-label'}>{label}</StyledLabel>
            {isEdit ? (
              <div>
                <StyledTextField
                  {...field}
                  {...formikprops}
                  inputProps={{ 'aria-labelledby': fieldName + '-label' }}
                  variant="filled"
                  fullWidth
                  id={fieldName}
                />
                {meta.touched && meta.error && <div className="error">{meta.error}</div>}
              </div>
            ) : (
              <div>
                <StyleDisabledTextField
                  {...field}
                  {...formikprops}
                  disabled
                  variant="filled"
                  inputProps={{ 'aria-label': label }}
                  fullWidth
                  id={fieldName}
                />
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
};

/**
 * UserInformationPanel is an element that allows a user to see and modify
 * certain parts of their profile.
 */
export const UserInformationPanel = props => {
  const { profile } = props;
  // TODO Auth0State Auth0Jwt
  const { error } = Auth0State();
  const auth0Jwt = Auth0Jwt();
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setMessage('');
    setIsError(false);
  }, [isEdit]);

  useErrorHandler(error);

  if (!profile) {
    return null;
  }

  const handleReset = resetForm => {
    resetForm();
    setIsEdit(!isEdit);
  };

  const submit = async (values, props) => {
    const { setSubmitting, setErrors } = props;
    // TODO Auth0Jwt
    const accessToken = auth0Jwt;

    let message = '';
    setMessage(message);
    setSubmitting(true);

    try {
      let payload = {
        lastName: values.lastName,
        firstName: values.firstName,
        primaryNumber: values.primaryNumber,
        location: values.location,
        jobTitle: values.jobTitle,
      };

      const { response, error } = await modifyUserProfile(accessToken, payload);
      // Modifying the user's profile was a failure
      if (!response?.ok) {
        setIsError(true);
        message = `Cannot save the profile changes at this time`;
        if (!error.status) {
          message = `Error communicating with the server, contact your administrator`;
        }
        if (error.status === 403) {
          message = `Credentials invalid, re-log in and try again`;
        }
      } else {
        message = 'Successfully updated your profile.';
        setIsEdit(false);
        setIsError(false);
      }
    } catch (err) {
      setErrors(err.message);
    }
    setMessage(message);
    setSubmitting(false);
  };

  return (
    <>
      <StyledCard>
        <InfoCardHeader
          title="My Information"
          action={
            <EditIconButton
              aria-label="Edit My Information"
              onClick={() => setIsEdit(!isEdit)}
              disabled={isEdit}
            >
              <EditIcon />
            </EditIconButton>
          }
        ></InfoCardHeader>
        <StyledCardContent>
          <Formik
            initialValues={{
              lastName: profile.lastName,
              firstName: profile.firstName,
              primaryNumber: profile.primaryNumber,
              location: profile.location,
              jobTitle: profile.jobTitle,
              emailAddress: profile.email,
            }}
            enableReinitialize={true}
            children={formikprops => (
              <Form onSubmit={formikprops.handleSubmit}>
                <FormikInputText
                  label="First Name"
                  placeholder="First Name"
                  fieldName="firstName"
                  isEdit={isEdit}
                  {...props}
                  {...formikprops}
                />
                <FormikInputText
                  label="Last Name"
                  placeholder="Last Name"
                  fieldName="lastName"
                  isEdit={isEdit}
                  {...props}
                  {...formikprops}
                />
                <FormikInputText
                  label="Email Address"
                  placeholder="Email Address"
                  fieldName="emailAddress"
                  isEdit={false}
                  {...props}
                  {...formikprops}
                />
                <FormikInputText
                  label="Job Title"
                  placeholder="Job Title"
                  fieldName="jobTitle"
                  isEdit={isEdit}
                  {...props}
                  {...formikprops}
                />
                <FormikInputText
                  label="Location"
                  placeholder="Location"
                  fieldName="location"
                  isEdit={isEdit}
                  {...props}
                  {...formikprops}
                />
                <FormikInputText
                  label="Phone Number (Optional)"
                  placeholder="Phone Number"
                  fieldName="primaryNumber"
                  isEdit={isEdit}
                  {...props}
                  {...formikprops}
                />
                <StyledDivider></StyledDivider>
                {isEdit ? (
                  <StyledEditButtonContainer>
                    <StyledCancelBtn
                      type="reset"
                      onClick={handleReset.bind(null, formikprops.resetForm)}
                    >
                      Cancel
                    </StyledCancelBtn>
                    <StyledSaveBtn type="submit">Save</StyledSaveBtn>
                  </StyledEditButtonContainer>
                ) : (
                  <StyledResetPwdBtnContainer>
                    <ChangePasswordModal profile={profile} />
                  </StyledResetPwdBtnContainer>
                )}
              </Form>
            )}
            onSubmit={(values, actions) => {
              submit(values, actions);
            }}
          />
        </StyledCardContent>
      </StyledCard>
      {message.length > 0 ? (
        <Notification message={message} isError={isError}></Notification>
      ) : null}
    </>
  );
};
