export const height = {
    xs: '500px',
    sm: '600px',
    md: '768px',
    lg: '1024px'
}

export const deviceHeight = {
    xs: `(min-height: ${height.xs})`,
    sm: `(min-height: ${height.sm})`,
    md: `(min-height: ${height.md})`,
    lg: `(min-height: ${height.lg})`
}