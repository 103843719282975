import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Snackbar, SnackbarContent } from '@mui/material';

const Notification = ({ message, isError }) => {
  const hasMessage = message?.length > 0;

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(true);
  }, [hasMessage]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <SnackbarContent
        style={{
          backgroundColor: isError ? '#DD372F' : '#00A887',
        }}
        message={message}
      />
    </Snackbar>
  );
};

Notification.propTypes = {
  message: PropTypes.string,
  isError: PropTypes.bool,
};

Notification.defaultProps = {
  message: [],
};

export default Notification;
