import React, { useEffect } from 'react';
import { Auth0State } from 'components/auth';
import { TENANT_STORAGE_KEY } from 'utils/configuration/tenants';

const IdleTimeOutHandler = props => {
  let timer = undefined;
  const events = ['click', 'scroll', 'load', 'keydown'];
  const authState = Auth0State();

  const eventHandler = eventType => {
    localStorage.setItem('lastInteractionTime', Date.now());
    if (timer) {
      startTimer();
    }
  };

  useEffect(() => {
    addEvents();

    return () => {
      removeEvents();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startTimer = () => {
    timer = setTimeout(() => {
      const diff = Date.now() - localStorage.getItem('lastInteractionTime');
      // 1000 milliseconds * 60 seconds * 15 minutes
      let timeOutInterval = 1000 * 60 * 15;
      if (diff < timeOutInterval) {
        startTimer();
      } else {
        // When logging out.. clear user tenants so they are refetched
        sessionStorage.removeItem(TENANT_STORAGE_KEY);
        authState.logout();
      }
    }, 1000 * 60 * 15);
  };

  const addEvents = () => {
    events.forEach(eventName => {
      window.addEventListener(eventName, eventHandler);
    });
    startTimer();
  };

  const removeEvents = () => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  return <div></div>;
};

export default IdleTimeOutHandler;
