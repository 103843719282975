import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import UnauthorizedApplicationAccess from "components/unauthorizedApplicationAccess/unauthorizedApplicationAccess";
import PlatformPage from 'pages/platformPage/platformPage';

import { APPLICATION_DASHBOARD_PATH } from "utils/dictionary";

import styled from 'styled-components';
import { Grid, Container, IconButton } from '@mui/material';
import { ArrowBackIcon } from 'components/icons/icons';

const StyledDiv = styled.div`
  padding-top: 20px;
`;

const StyledPrevPageButton = styled(IconButton)`
  font-size: ${props => props.theme.typeScale[2]};
  color: ${props => props.theme.colors.primary};
  margin-left: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  &:hover {
    background-color: ${props => props.theme.colors.lighterGrey};
  }
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  color: ${props => props.theme.colors.darkPrimary};
`;

export const UnauthorizedAccess = () => {
    const navigate = useNavigate();
    const { application, email } = useParams();

    const goToMyHealthwise = () => {
        navigate(APPLICATION_DASHBOARD_PATH);
    };

    return (
        <>
            <PlatformPage>
                <Container style={{ marginBottom: '5em', flex: '1 1 auto', zIndex: '900' }} maxWidth="xl">
                    <StyledDiv>
                        <StyledPrevPageButton onClick={goToMyHealthwise}>
                            <StyledArrowBackIcon />
                            Back to MyHealthwise
                        </StyledPrevPageButton>
                    </StyledDiv>

                    <Grid container direction="row" justifyContent="center">
                        <Grid item>
                            <UnauthorizedApplicationAccess email={email} application={application} />
                        </Grid>
                    </Grid>
                </Container>
            </PlatformPage>
        </>
    );
};