import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
  GeneralCancelButton,
  ConfirmationModalButton,
  ConfirmationModalIconEndButton,
} from 'components/buttons/button';
import { DEFAULT_BTN_COLOR_CONFIRM_GREEN } from 'utils/dictionary/overview';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import style from './confirmationModal.module.scss';

const StyledDialogTitle = styled(DialogTitle)`
  margin: 2px 0 0 0;
  padding-bottom: 0;
  color: ${props => props.theme.colors.darkPrimary};
  letter-spacing: 0;
`;

const ConfirmationModal = props => {
  const {
    confirmText,
    handleClose,
    handleCancel,
    handleConfirm,
    open,
    text,
    title,
    color = DEFAULT_BTN_COLOR_CONFIRM_GREEN,
    isDisabled,
    isError,
    message,
    isSubmitting,
    icon = null,
    titleClassname = '',
    bodyClassName = '',
    cancelButtonClassName = '',
  } = props;

  const internalConfirmationHandler = async eventHandler => {
    await eventHandler();
  };

  const disabledStyle = {
    color: 'white',
    backgroundColor: '#0d848480',
    border: 'none',
  };

  return (
    <>
      <div className={style.ariaLabel} id="dialogAriaLabel">
        {title}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialogAriaLabel"
        role="dialog"
        closeAfterTransition
      >
        <StyledDialogTitle className={titleClassname}>{title}</StyledDialogTitle>
        <DialogContent>
          <DialogContent className={[style.bodyText, bodyClassName].join(' ')}>
            {text}
          </DialogContent>
          {isError ? (
            <DialogContent className={style.errorText}>{message}</DialogContent>
          ) : (
            <span></span>
          )}
        </DialogContent>
        <DialogActions className={style.rightAlignedButtonGroup}>
          <GeneralCancelButton
            className={[style.cancelButtonMain, cancelButtonClassName].join(' ')}
            handleClick={handleCancel}
          ></GeneralCancelButton>
          {isSubmitting ? (
            <ProgressIndicator />
          ) : icon === null ? (
            <ConfirmationModalButton
              style={isDisabled ? disabledStyle : null}
              handleClick={() => {
                internalConfirmationHandler(handleConfirm);
              }}
              buttonText={confirmText}
              backgroundColor={color}
              disableRipple
              disabled={isDisabled != null && isDisabled}
            >
              {confirmText}
            </ConfirmationModalButton>
          ) : (
            <ConfirmationModalIconEndButton
              style={isDisabled ? disabledStyle : null}
              handleClick={() => {
                internalConfirmationHandler(handleConfirm);
              }}
              buttonText={confirmText}
              backgroundColor={color}
              disableRipple
              disabled={isDisabled != null && isDisabled}
              endIcon={icon}
            >
              {confirmText}
            </ConfirmationModalIconEndButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  confirmText: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  color: PropTypes.string,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  message: PropTypes.string,
  isSubmitting: PropTypes.bool,
};
