/* START IE11 polyfills */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
/* END IE11 polyfills */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { StyledEngineProvider } from '@mui/material/styles';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { theme } from 'utils/styles/styles';

const container = document.getElementById('root');
const root = createRoot(container);

// StyledEngineProvider is needed for leveraging StyledComponent with Material v5
root.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <App tab="home" />
    </StyledEngineProvider>
  </ThemeProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
