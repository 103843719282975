const safelog = params => {
  try {
    console.log(params);
  } catch (err) {
    // Do nothing, we don't want logging to break the app
  }
};

const safeError = params => {
  try {
    console.error(params);
  } catch (err) {
    // Do nothing, we don't want logging to break the app
  }
};

export const trackEvent = params => {
  safelog(params);
};

export const trackException = params => {
  safeError(params);
};
