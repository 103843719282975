import { InputLabel, Select, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import styled from "styled-components";

const StyledBanner = styled.div`
    background-color: #f4f4f4;
    height: 20vh;
    width: 100%;
    display: flex;
    flex-flow: row no-wrap;
    align-items: center;
    position: absolute;
    top: 6vh;
    left: 0;
`;

const StyledContentContainer = styled.div`
    margin: 0 auto;
`;

const StyledHeader = styled.h2`
    margin: .5em 0;
    font-weight: lighter;
`;

const StyledSelectContainer = styled.div`
    display: flex;
    flex-flow: row no-wrap;
    align-items: center;
`;

const StyledInputLabel = styled(InputLabel)`
    margin-right: 1em;
`;

const StyledSelect = styled(Select)`
    min-width: 350px;
    height: 40px;
`;

const Dropdown = ({ tenants }) => {
    const [currentTenant, setCurrentTenant] = useState(tenants[0]);

    const changeTenant = (e) => {
        setCurrentTenant(e.target.value);
        window.location.assign(e.target.value.url);
    }

    return (
        <StyledSelectContainer>
            <StyledInputLabel id="tenant-select">Select a tenant:</StyledInputLabel>
            <StyledSelect
                labelId="tenant-select"
                id="tenant-select-dropdown"
                value={currentTenant}
                onChange={changeTenant}>
                {tenants.map((tenant, i) => {
                    return <MenuItem key={i} value={tenant}>{tenant.name}</MenuItem>
                })}
            </StyledSelect>
        </StyledSelectContainer>
    );
}

export const TenantDropdown = ({ user, tenants }) => {
    return (
        <StyledBanner>
            <StyledContentContainer>
                <StyledHeader>Welcome, {user.firstName}</StyledHeader>
                <Dropdown tenants={tenants} />
            </StyledContentContainer>
        </StyledBanner>
    );
}

TenantDropdown.propTypes = {
    user: PropTypes.object.isRequired,
    tenants: PropTypes.array.isRequired
}