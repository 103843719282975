import { createResource, putResource } from 'utils/api/common/invocationUtils';
import * as configuration from 'utils/dictionary/profile.js';

/**
 * Modify the current user
 * @param {string} accessToken A valid access token
 * @param {Object} payload The updated profile values (only first and/or last name)
 */
// TODO: Add etag
export async function modifyUserProfile(accessToken, payload) {
  let etag = '';
  console.group('preModifyUserProfile');
  console.log(`${configuration.profileUrl}`);
  console.log(payload);
  console.groupEnd('preModifyUserProfile');
  let response = await putResource(`${configuration.profileUrl}`, payload, etag, accessToken);

  console.group('modifyUserProfile');
  console.log(JSON.stringify(response));
  console.groupEnd('modifyUserProfile');

  return response;
}

export async function changePassword(url, values, accessToken) {
  let response = await createResource(url, values, accessToken);

  console.group('modifyUserProfile');
  console.log(JSON.stringify(response));
  console.groupEnd('modifyUserProfile');

  return response;
}
