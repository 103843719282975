export function isCorrectLength(value, length) {
  if (value.length >= length) {
    return true;
  } else {
    return false;
  }
}

export function containsNumber(value) {
  const regExp = /\d/;
  if (regExp.test(value)) {
    return true;
  } else {
    return false;
  }
}

export function containsUppercase(value) {
  const regExp = /[A-Z]/;
  if (regExp.test(value)) {
    return true;
  }
  return false;
}

export function containsLowercase(value) {
  const regExp = /[a-z]/;
  if (regExp.test(value)) {
    return true;
  }
  return false;
}

/**
 * password policy requires that passwords do not contain any part of a user's email when split on the following charactesr:
 *  , . _ @ #
 * @param {password} password
 * @param {email} email
 * @returns boolean true or false
 */
export function doesNotContainUsername(password, email) {
  var separators = ['\\@', '_', '\\.', ',', '#'];

  if (password === null || password.length === 0) {
    return false;
  }
  const splitEmailParts = email.split(new RegExp(separators.join('|'), 'g'));

  splitEmailParts.forEach(value => {
    if (value.length < 4) {
      splitEmailParts.splice(splitEmailParts.indexOf(value), 1);
    }
  });
  if (splitEmailParts.some(value => password.toLocaleLowerCase().includes(value))) {
    return false;
  } else {
    return true;
  }
}

export function doPasswordsMatch(password, confirmNewPassword, oldpassword) {
  if (confirmNewPassword === password) {
    return true;
  } else {
    return false;
  }
}

export function newPasswordMatchesOldPassword(oldPassword, password, confirmNewPassword) {
  if (oldPassword === password || oldPassword === confirmNewPassword) {
    //cannot set new password to be old password
    return true;
  } else {
    return false;
  }
}

export function hasOldPasswordValue(oldpassword) {
  if (oldpassword === '') {
    return false;
  } else {
    return true;
  }
}

export function hasNewPasswordValue(password) {
  if (password === '') {
    return false;
  } else {
    return true;
  }
}

export function hasConfirmNewPasswordValue(password) {
  if (password === '') {
    return false;
  } else {
    return true;
  }
}
