// eslint-disable
import React, { useState, useEffect, useRef } from 'react';
import { Auth0Jwt } from 'components/auth';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import style from './userMenu.module.scss';
import { IconButton } from '@mui/material';
import {
  KeyboardArrowDownIcon,
  AppsIcon,
  HelpIcon,
  UserProfileIcon,
  ToolsIcon,
  FeedbackIcon,
  LogoutIcon,
} from 'components/icons/icons';
import {
  HEADER_ACCOUNT_MENU_ARIA,
  USER_MENU_MYHEALTHWISE_BUTTON_TEXT,
  USER_MENU_MY_PROFILE_BUTTON_TEXT,
  USER_MENU_GET_HELP_TEXT,
  HELP_URL,
  LOG_SERVICE_REQUEST_URL,
  SIGNOUT_PATH,
  USER_MENU_SIGN_OUT_TEXT,
  USER_MENU_LOG_SERVICE_REQUEST_TEXT,
  USER_MENU_SUBMIT_CONTENT_FEEDBACK_TEXT,
} from 'utils/dictionary';
import {
  APPLICATION_DASHBOARD_PATH,
  PROFILE_PATH,
  CONTENT_FEEDBACK_PATH,
} from 'utils/dictionary/routes';

import { useShowContentFeedback } from 'utils/hooks/displayComponent/useShowContentFeedback';
import { useShowLogRequest } from 'utils/hooks/displayComponent/useShowLogRequest';

export const ancestorHasId = (el, id) => {
  let parent = el;
  while (parent) {
    parent = parent.parentElement;
    if (parent && parent.id === id) {
      return parent;
    }
  }
  return undefined;
};

const UserMenu = ({ user }) => {
  const { firstName, lastName, email } = user;
  const name = `${firstName} ${lastName}`;
  const [visible, setVisible] = useState(false);
  const [userMenuItems, setUserMenuItems] = useState();
  const { search } = useLocation();
  const { showContentFeedback } = useShowContentFeedback();
  const { showLogRequest } = useShowLogRequest();

  const userInfoContainerId = 'userInfoContainer';

  const jwt = Auth0Jwt();
  const helpUrl = HELP_URL + '#' + jwt;
  const logServiceRequestUrl = LOG_SERVICE_REQUEST_URL + '#' + jwt;

  const clickListener = e => {
    const isNotControlsParent = !ancestorHasId(e.target, userInfoContainerId);
    if (isNotControlsParent) {
      setVisible(false);
      removeClickHandler();
    }
  };
  const removeClickHandler = () => {
    window.removeEventListener('click', clickListener);
  };

  const addClickHandler = () => {
    window.addEventListener('click', clickListener);
  };

  const firstMenuButton = useRef(null);
  const menuButton = useRef(null);

  useEffect(() => {
    setUserMenuItems(document.querySelectorAll('[role="menuitem"]'));
  }, [visible]);

  useEffect(() => {
    if (firstMenuButton?.current && visible) {
      setTimeout(() => {
        firstMenuButton.current.focus();
      }, 50);
    }
  }, [firstMenuButton, visible]);

  var currentIndex = 0;

  var gotoIndex = function (idx) {
    if (idx === userMenuItems.length) {
      idx = 0;
    } else if (idx < 0) {
      idx = userMenuItems.length - 1;
    }
    userMenuItems[idx].focus();
    currentIndex = idx;
  };

  const handleClick = e => {
    e.stopPropagation();
    setVisible(!visible);
    setTimeout(() => {
      if (visible && menuButton?.current) {
        menuButton.current.focus();
        removeClickHandler();
      } else {
        addClickHandler();
      }
    }, 50);
  };

  //https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
  var keys = {
    tab: 'Tab',
    enter: 'Enter',
    esc: 'Escape',
    space: 'Space',
    left: 'ArrowLeft',
    up: 'ArrowUp',
    right: 'ArrowRight',
    down: 'ArrowDown',
  };

  const keyPressEventListener = event => {
    var prevdef = true;
    switch (event.code) {
      case keys.tab:
        if (event.shiftKey) {
          gotoIndex(currentIndex - 1);
        } else {
          gotoIndex(currentIndex + 1);
        }
        prevdef = true;
        break;
      case keys.right:
        prevdef = true;
        break;
      case keys.left:
        prevdef = true;
        break;
      case keys.esc:
        setVisible(false);
        if (menuButton?.current) {
          menuButton.current.focus();
          removeClickHandler();
        }
        prevdef = true;
        break;
      case keys.down:
        gotoIndex(currentIndex + 1);
        prevdef = true;
        break;
      case keys.up:
        gotoIndex(currentIndex - 1);
        prevdef = true;
        break;
      case keys.enter:
      case keys.space:
        event.target.click();
        prevdef = false;
        break;
      default:
        prevdef = true;
        break;
    }

    if (prevdef) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
  const navigate = useNavigate();

  const goToApplications = () => {
    navigate({
      pathname: APPLICATION_DASHBOARD_PATH,
      search: search,
    });
  };
  const goToProfile = () => {
    navigate({
      pathname: PROFILE_PATH,
      search: search,
    });
  };

  const goToSignOut = () => {
    navigate(SIGNOUT_PATH);
  };

  const goToHelp = () => {
    window.open(helpUrl, '_blank');
  };

  const goToLogServiceRequest = () => {
    window.open(logServiceRequestUrl, '_blank');
  }

  const goToContentFeedback = () => {
    navigate({
      pathname: CONTENT_FEEDBACK_PATH,
      search: search, 
    });
  }

  return (
    <>
      <IconButton
        id="usermenubutton"
        aria-controls="menu"
        aria-haspopup="true"
        aria-label={HEADER_ACCOUNT_MENU_ARIA}
        aria-expanded={Boolean(visible)}
        onClick={handleClick}
        ref={menuButton}
        style={{ padding: '0 12px' }}
        className={`${style.profileButton} ${ visible ? style.buttonActive : '' }`}
      >
        <KeyboardArrowDownIcon className={style.downArrowIcon} />
      </IconButton>

      <div
        id={userInfoContainerId}
        data-testid={userInfoContainerId}
        className={`${style.userInfoContainer} ${visible ? '' : style.invisible}`}
      >
        <nav className={style.nav}>
          <div className={style.header}>
            <p className={style.name}>{name}</p>
            <p className={style.email}>{email}</p>
          </div>

          <div className={style.divider} />

          <ul id="userMenu" role="menu" className={style.menu}>
            <li
              role="menuitem"
              className={style.myHealthwiseItem}
              tabIndex="0"
              onKeyDown={keyPressEventListener}
              onClick={goToApplications}
              ref={firstMenuButton}
              aria-label={`${USER_MENU_MYHEALTHWISE_BUTTON_TEXT} - Link`}
            >
              <AppsIcon className={style.icon} />
              <NavLink className={style.link}>
                <span>{USER_MENU_MYHEALTHWISE_BUTTON_TEXT}</span>
              </NavLink>
            </li>
          </ul>

          <div className={style.divider} />

          <ul role="menu" className={style.menu}>
            <li
              role="menuitem"
              className={style.menuItem}
              onClick={goToProfile}
              onKeyDown={keyPressEventListener}
              tabIndex="0"
              aria-label={`${USER_MENU_MY_PROFILE_BUTTON_TEXT} - Link`}
            >
              <NavLink className={style.link}>
                <UserProfileIcon className={style.icon} />
                {USER_MENU_MY_PROFILE_BUTTON_TEXT}
              </NavLink>
            </li>
            <li
              role="menuitem"
              className={style.menuItem}
              onClick={goToHelp}
              onKeyDown={keyPressEventListener}
              tabIndex="0"
              aria-label={`${USER_MENU_GET_HELP_TEXT} - Link - opens new tab`}
            >
              <NavLink className={style.link} rel="noreferrer">
                <HelpIcon className={style.icon} />
                {USER_MENU_GET_HELP_TEXT}
              </NavLink>
            </li>
            {showLogRequest ? (
              <li
                role="menuitem"
                className={style.menuItem}
                onClick={goToLogServiceRequest}
                onKeyDown={keyPressEventListener}
                tabIndex="0"
                aria-label={`${USER_MENU_LOG_SERVICE_REQUEST_TEXT} - Link - opens new tab`}
              >
                <NavLink className={style.link} rel="noreferrer">
                  <ToolsIcon className={style.icon} />
                  <span style={{ paddingLeft: '5px' }}>{USER_MENU_LOG_SERVICE_REQUEST_TEXT}</span>
                </NavLink>
              </li>
            ) : (
              <li tabIndex="-1" className={style.menuLiHide}></li>
            )}
            {showContentFeedback ? (
              <li
                role="menuitem"
                className={style.menuItem}
                onClick={goToContentFeedback}
                onKeyDown={keyPressEventListener}
                tabIndex="0"
                aria-label={`${USER_MENU_SUBMIT_CONTENT_FEEDBACK_TEXT} - Link`}
              >
                <NavLink className={style.link} rel="noreferrer">
                  <FeedbackIcon className={style.icon} />
                  <span style={{ paddingLeft: '5px' }}>{USER_MENU_SUBMIT_CONTENT_FEEDBACK_TEXT}</span>
                </NavLink>
              </li>
            ) : (
              <li tabIndex="-1" className={style.menuLiHide}></li>
            )}
          </ul>

          <div className={style.divider} />

          <ul role="menu" className={style.menu}>
            <li
              role="menuitem"
              className={style.menuItem}
              onClick={goToSignOut}
              onKeyDown={keyPressEventListener}
              tabIndex="0"
              aria-label={`${USER_MENU_SIGN_OUT_TEXT} - Link`}
            >
              <NavLink className={style.link}>
                <LogoutIcon className={style.icon} />
                {USER_MENU_SIGN_OUT_TEXT}
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default UserMenu;
