import * as utils from 'utils/api/common/invocationUtils';
import * as EnvDictionary from 'utils/dictionary/env';

const settingUrl = `${EnvDictionary.REACT_APP_PROVISIONING_MANAGER_API_BASE_URL}/v1/setting`;

export async function getSubscriptionSettings(subscriptionId, accessToken) {
    return await utils.getResource(
        `${settingUrl}/subscription/${subscriptionId}`,
        accessToken
    );
}