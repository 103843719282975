import React from 'react';
import styled from 'styled-components';

import { Chip, Grid, Card, CardHeader, CardContent, Typography } from '@mui/material';
import ToolTip from 'components/tooltip/tooltip';
import {
  ROLES_AND_PERMISSIONS_TOOLTIP_EXPLANATION,
  USER_PROFILE_ROLES_TITLE,
  SUBSCRIPTION_NAME,
} from 'utils/dictionary';

const A11Y_MESSAGE = 'More information about roles and permissions.';

const RoleChip = styled(Chip)`
  margin: 0 ${props => props.theme.spacingScale[1]} ${props => props.theme.spacingScale[1]} 0;
  background-color: ${props => props.theme.colors.opaqueLightGrey};
  &.MuiChip-root {
    border-radius: ${props => props.theme.typeScale[2]};
  }
`;

const RoleChipList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline;
`;

const RoleChipListItem = styled.li`
  display: contents;
`;

const StyledCard = styled(Card)`
  border-radius: 8px 8px 8px 8px;
`;

const StyledAppCard = styled(Card)`
  border-radius: 4px 4px 4px 4px;
  margin: ${props => props.theme.spacingScale[2]} 0;
`;

const StyledCardContent = styled(CardContent)`
  padding: ${props => props.theme.spacingScale[4]};
  &.MuiCardContent-root:last-child {
    padding-bottom: ${props => props.theme.spacingScale[2]};
  }
`;

const InfoCardHeader = styled(CardHeader)`
  border-radius: 3px 3px 0px 0px;
  height: ${props => props.theme.spacingScale[5]};
  background-color: ${props => props.theme.colors.background};
  padding-left: ${props => props.theme.spacingScale[4]};
  font-weight: bold;
  .MuiTypography-h5 {
    font-size: ${props => props.theme.typeScale[4]};
  }
`;

const ApplicationHeader = styled(CardHeader)`
  background-color: ${props => props.theme.colors.background};

  .MuiCardHeader-title {
    font-size: ${props => props.theme.typeScale[0]};
    @media (max-width: 600px) {
      display: none;
    }
  }
`;
const AppName = styled.p`
  margin: 0px;
  padding-bottom: 15px;
`;

const StyledSubscriptionName = styled.p`
  margin: 2px 0 0 0;
  font-size: ${props => props.theme.typeScale[4]};
`;

const CardHeaderText = styled(Typography)`
  font-size: ${props => props.theme.typeScale[4]};
  letter-spacing: 0em;
`;

/**
 * Render all the roles as chips.
 */
const RolePanel = props => {
  const { roles } = props;

  if (!roles) {
    return null;
  }

  return (
    <RoleChipList>
      {roles.map(role => {
        return (
          <RoleChipListItem key={role.referenceId}>
            <RoleChip label={role.role} onDelete={undefined} />
          </RoleChipListItem>
        );
      })}
    </RoleChipList>
  );
};

/**
 * Render an application and all of the roles assigned.
 */
const ApplicationPanel = props => {
  const { roles } = props;

  if (!roles) {
    return null;
  }

  const applicationName = roles[0].applicationName;
  const roleIds = [...new Set(roles.map(role => role.role))];

  return (
    <>
      <Grid item xs={12} sm={12} md={6}>
        <AppName>{applicationName}</AppName>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        {roleIds.map(roleId => {
          const selectedRoles = roles.filter(role => {
            return role.role === roleId;
          });
          return <RolePanel key={roleId} roles={selectedRoles} />;
        })}
      </Grid>
    </>
  );
};

/**
 * Render the Header information for the applications
 */
const ApplicationHeaderPanel = props => {
  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={12} md={6}>
        Application
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        Application Roles
      </Grid>
    </Grid>
  );
};

/**
 * Render a single subscription of applications and roles for a user.
 */
const SubscriptionPanel = props => {
  const { roles } = props;

  if (!roles) {
    return null;
  }

  const applicationIds = [...new Set(roles.map(role => role.applicationId))];

  return (
    <>
      <StyledSubscriptionName>
        {SUBSCRIPTION_NAME}
        <b>{roles[0]?.subscriptionName}</b>
      </StyledSubscriptionName>
      <StyledAppCard>
        {applicationIds.length > 0 ? (
          <>
            <ApplicationHeader title={<ApplicationHeaderPanel />} />
            <StyledCardContent>
              <Grid container direction="row">
                {applicationIds.map(applicationId => {
                  const selectedRoles = roles.filter(role => {
                    return role.applicationId === applicationId;
                  });
                  return <ApplicationPanel key={applicationId} roles={selectedRoles} />;
                })}
              </Grid>
            </StyledCardContent>
          </>
        ) : null}
      </StyledAppCard>
    </>
  );
};

/**
 * Render all of the users subscriptions, applications, and roles in a read-only panel.
 */
const RoleDisplayPanel = props => {
  const { roles } = props;

  const subscriptionIds = [...new Set(roles.map(role => role.subscriptionId))];

  if (!roles) {
    return null;
  }

  return (
    <StyledCard>
      <InfoCardHeader
        title={
          <>
            <CardHeaderText align="left" display="inline">
              {USER_PROFILE_ROLES_TITLE}
            </CardHeaderText>
            <ToolTip
              aria-label={A11Y_MESSAGE}
              innerTitle={ROLES_AND_PERMISSIONS_TOOLTIP_EXPLANATION}
            />
          </>
        }
      />
      <StyledCardContent>
        {subscriptionIds.map(subscriptionId => {
          const selectedRoles = roles.filter(role => {
            return role.subscriptionId === subscriptionId;
          });
          return <SubscriptionPanel key={subscriptionId} roles={selectedRoles} />;
        })}
      </StyledCardContent>
    </StyledCard>
  );
};

export default RoleDisplayPanel;
