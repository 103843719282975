/** Maintainer's Note:
 * When adding icons into this component, please make sure to keep the list alphabetical
 */

import {
  AccountCircle,
  ArrowBack,
  ArrowBackIos,
  ArrowDropDown,
  ArrowRight,
  ArrowForward,
  Block,
  Business,
  Cancel,
  Check,
  CheckCircle,
  ChevronRight,
  Clear,
  Close,
  Delete,
  Done,
  Edit,
  Email,
  Error,
  ExpandMore,
  Help,
  HelpOutline,
  HomeRounded,
  Info,
  KeyboardArrowDown,
  Logout,
  Loop,
  MenuRounded,
  MoreHoriz,
  Person,
  PersonAdd,
  PlayCircleOutline,
  RemoveCircleOutline,
  Search,
  Send,
  Settings,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';

import { ReactComponent as Deactivate } from 'assets/icons/deactivate-user.svg';
import { ReactComponent as DomainAdd } from 'assets/icons/domain-add.svg';
import { ReactComponent as SwitchOff } from 'assets/icons/switch-off.svg';
import { ReactComponent as SwitchOn } from 'assets/icons/switch-on.svg';
import { ReactComponent as AngleDown } from 'assets/icons/angle-down.svg';
import { ReactComponent as AngleUp } from 'assets/icons/angle-up.svg';
import { ReactComponent as UserCogColor } from 'assets/icons/user-cog-c.svg';
import { ReactComponent as DiagramColor } from 'assets/icons/diagram-c.svg';
import { ReactComponent as DelegateColor } from 'assets/icons/delegate-c.svg';
import { ReactComponent as ForceReset } from 'assets/icons/force-reset.svg';
import { ReactComponent as WhiteLogo } from 'assets/icons/webmd-ignite-logo-my-healthwise.svg';
import { ReactComponent as Logo } from 'assets/icons/webmd-ignite-logo.svg';
import { ReactComponent as Delegate } from 'assets/icons/delegate.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { ReactComponent as LockReset } from 'assets/icons/lock_reset.svg';
import { ReactComponent as RemoveModerator } from 'assets/icons/remove_moderator.svg';
import { ReactComponent as Apps } from 'assets/icons/apps.svg';
import { ReactComponent as Feedback } from 'assets/icons/feedback.svg';
import { ReactComponent as CustomerService } from 'assets/icons/customer-service.svg';
import { ReactComponent as Tools } from 'assets/icons/mdi_tools.svg';

import style from './icons.module.scss';
import styled from 'styled-components';

export const AppsIcon = props => <Apps {...props} />;

export const AccountCircleIcon = props => {
  const { fontSize = 'large', ...otherProps } = props;
  return <AccountCircle fontSize={fontSize} {...otherProps} />;
};

export const AngleDownIcon = props => <AngleDown style={{ width: '1rem' }} {...props} />;

export const AngleUpIcon = props => <AngleUp style={{ width: '1rem' }} {...props} />;

export const ArrowBackIcon = props => <ArrowBack {...props} />;

export const ArrowBackIosIcon = props => <ArrowBackIos {...props} />;
export const ArrowForwardIcon = props => <ArrowForward {...props} />;

// TODO color doesn't match a theme reference
export const ArrowDropDownIcon = props => <ArrowDropDown style={{ color: '#898989' }} {...props} />;

export const ArrowRightIcon = props => <ArrowRight {...props} />;

export const BlockIcon = props => <Block {...props} />;

export const BusinessIcon = props => <Business {...props} />;

export const CancelIcon = props => <Cancel {...props} />;

export const CheckIcon = props => <Check {...props} />;

export const CheckCircleIcon = props => <CheckCircle {...props} />;

export const ChevronRightIcon = props => <ChevronRight {...props} />;

export const ClearIcon = props => <Clear {...props} />;

export const CloseIcon = props => <Close {...props} />;

export const CustomerServiceIcon = props => <CustomerService {...props} />;

export const DeactivateIcon = props => (
  <Deactivate style={{ color: '#dd372f', width: '1.5rem' }} {...props} />
);

export const DelegateColorIcon = props => <DelegateColor style={{ width: '1.5rem' }} {...props} />;

export const DelegateIcon = props => <Delegate {...props} />;

export const DeleteIcon = props => {
  const { className = '', ...otherProps } = props;
  return <Delete className={`${style.deleteIcon} ${className}`} {...otherProps} />;
};

export const DiagramColorIcon = props => <DiagramColor {...props} />;

export const DomainAddIcon = props => <DomainAdd {...props} />;

export const DoneIcon = props => <Done {...props} />;

export const EditIcon = props => <Edit {...props} />;

export const EmailIcon = props => <Email {...props} />;

export const ErrorIcon = props => (
  <Error style={{ color: '#dd372f', paddingBottom: '0.75rem' }} {...props} />
);

export const ExpandMoreIcon = props => <ExpandMore {...props} />;

export const FeedbackIcon = props => <Feedback {...props} />;

export const ForceResetIcon = props => <ForceReset style={{ width: '1.5rem' }} {...props} />;

export const HelpIcon = props => <Help {...props} />;

export const HelpOutlineIcon = props => <HelpOutline {...props} />;

export const HomeRoundedIcon = props => <HomeRounded {...props} />;

export const InfoIcon = props => <Info {...props} />;

export const KeyboardArrowDownIcon = props => <KeyboardArrowDown {...props} />;

export const LockResetIcon = props => (
  <LockReset style={{ width: '1.5rem', fill: '#424242' }} {...props} />
);

export const LogoIcon = props => <Logo {...props} />;

export const LogoutIcon = props => <Logout {...props} />;

export const MenuRoundedIcon = props => <MenuRounded {...props} />;

export const MoreHorizIcon = props => <MoreHoriz style={{ color: '#424242' }} {...props} />;

export const PersonAddIcon = props => <PersonAdd {...props} />;

export const PlayCircleOutlineIcon = props => <PlayCircleOutline {...props} />;

export const RemoveIcon = props => <RemoveCircleOutline {...props} />;

export const RemoveModeratorIcon = props => (
  <RemoveModerator style={{ width: '1.5rem', fill: '#DD372F' }} {...props} />
);

export const ResetIcon = props => <Loop style={{ width: '1.5rem', fill: '#424242' }} {...props} />;

export const SearchIcon = props => <Search {...props} />;

export const SendIcon = props => <Send style={{ fill: '#424242' }} {...props} />;

export const SettingsIcon = props => <Settings {...props} />;

export const SuccessIcon = props => (
  <Done style={{ color: '#006272', paddingBottom: '14px' }} {...props} />
);

export const ToggleOffIcon = props => <SwitchOff style={{ width: '1.6rem' }} {...props} />;

export const ToggleOnIcon = props => <SwitchOn style={{ width: '1.6rem' }} {...props} />;

export const ToolsIcon = props => <Tools {...props} />;

export const TrashCanIcon = props => <DeleteIcon {...props} />;

export const UserCogColorIcon = props => <UserCogColor {...props} />;

export const UserIcon = props => <User {...props} />;

export const UserProfileIcon = props => <Person {...props} />;

export const WhiteLogoIcon = props => <WhiteLogo {...props} />;

export const VisibilityOffIcon = props => <VisibilityOff {...props} />;

export const VisibilityOnIcon = props => <Visibility {...props} />;

// These are needed for some extracted icons
const IconContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Svg = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
  fill: ${props => props.theme.colorTextPrimary};
`;

const PathContrast = styled.path`
  fill: ${props => props.theme.colors.lightPrimary};
`;

const PathPrimaryStroke = styled.path`
  stroke: ${props => props.theme.colors.primaryText};
`;

// DefaultApplicationIcon is the Healthwise-UI BrandingIcon
// TODO: Pull this out into a standalone svg
export const DefaultApplicationIcon = props => {
  return (
    <IconContainer>
      <Svg focusable="false" viewBox="0 0 96 96" {...props}>
        <PathPrimaryStroke
          as="rect"
          x="2.64"
          y="7.36"
          width="90.67"
          height="59"
          rx="2.88"
          ry="2.88"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <PathContrast d="M31.06 25.81l8.31 9.9 12.37-20.15 22.09 38-60.04.06 17.27-27.81z" />
        <PathPrimaryStroke
          as="rect"
          x="28.4"
          y="84.69"
          width="39.68"
          height="5.83"
          rx="2.57"
          ry="2.57"
          fill="none"
          strokeWidth="1.5"
        />
        <PathPrimaryStroke
          d="M28.72 85.94l7.83-19.7m23.34 0l7.75 19.54"
          strokeMiterlimit="10"
          fill="none"
          strokeWidth="1.5"
        />
        <PathPrimaryStroke
          d="M37.73 29.31l8.3 9.9 12.38-20.15 22.09 38-60.04.06 17.27-27.81z"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </IconContainer>
  );
};
