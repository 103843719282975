import { get, post, patch, put, _delete } from 'utils/http';

/**
 *  The common response object for all of the HTTP invocation methods.
 *
 *  @typedef {Object} Response
 *  @property {Object} response - The raw response from the HTTP invocation
 *  @property {Object} data - The parsed JSON of the response
 *  @property {Object} error - The error state of the Fetch call, if needed
 */

/**
 * A response object for the GET invocation method that contains an etag value.
 * The Etag is used to determine on other invocations if the caller has the
 * latest value returned from the endpoint.
 *
 *  @typedef {Object} ETaggedResponse
 *  @property {Object} response - The raw response from the HTTP invocation
 *  @property {Object} data - The parsed JSON of the response
 *  @property {string} etag - The ETag showing that the last modified state of the returned value
 *  @property {Object} error - The error state of the Fetch call, if needed
 */

/**
 * A response object for the GET invocation method that is part of a paged
 * response. Included in the response is the information needed to navigate to
 * different 'pages' of data.
 *
 *  @typedef {Object} PagedResponse
 *  @property {Object} response - The raw response from the HTTP invocation
 *  @property {Object} data - The parsed JSON of the response
 *  @property {Object} pagination - Information about the status of the results
 *  @property {Object} nextPage - The function that can be executed to retrieve the next page of results.
 *  @property {Object} error - The error state of the Fetch call, if needed
 */

/**
 * Gets a particular number of results from an endpoint.
 *
 * @param {string} url - the URL to call
 * @param {number} skip - the number of records to 'skip', or the record number to start retrieving at
 * @param {number} top - the limit of records to return in this call
 * @param {string} accessToken - A valid access token of the 'Bearer' type
 * @param {string} search - the search string, optional
 * @param {string} orderByField - which field to sort on, optional
 * @param {string} orderByDirection - how to sort, default is 'asc'
 * @param {Object} query - additional query parameters, optional
 *
 * @returns {PagedResponse} The response from the invocation
 */
export async function getPaginatedResult(
  url,
  skip,
  top,
  accessToken,
  search = '',
  orderByField = '',
  orderByDirection = 'asc',
  query = {},
) {
  console.count('getPaginatedResult');
  const delimiter = url.includes('?') ? '&' : '?';

  let resourceLocation = `${url}${delimiter}skip=${skip}&top=${top}&search=${search}&orderByField=${orderByField}&orderByDirection=${orderByDirection}`;

  Object.keys(query).forEach(
    key => (resourceLocation = `${resourceLocation}&${key}=${query[key]}`),
  );

  const { response, json, error } = await get(resourceLocation, accessToken);

  const pagination = json?.meta?.pagination || {
    returned: 0,
    available: 0,
    skip: 0,
    top: 10,
  };

  pagination.page = pagination.skip / pagination.top + 1;

  const nextPage =
    response?.ok && pagination.available > top + skip
      ? async () => {
          return await getPaginatedResult(url, skip + pagination.returned, top, accessToken);
        }
      : null;

  return {
    response,
    data: json,
    pagination: pagination,
    nextPage: nextPage,
    error: error,
  };
}

/**
 * Gets a particular resource as detailed by the URL.
 *
 * @param {string} url - the URL to call
 * @param {string} accessToken - A valid access token of the 'Bearer' type
 *
 * @returns {ETaggedResponse} The response from the invocation
 */
export async function getResource(url, accessToken) {
  //console.count('getResource');
  const { response, json, error } = await get(url, accessToken);

  return {
    response,
    data: json,
    etag: response?.headers?.get('etag'),
    error: error,
  };
}

/**
 * Creates a particular resource as detailed by the URL.
 *
 * @param {string} url - the URL to call
 * @param {Object} body - an object containing the properties to create
 * @param {string} etag - the property from the last get invocation to indicate that this resource has not changed
 * @param {string} accessToken - A valid access token of the 'Bearer' type
 *
 * @returns {Response} The response from the invocation
 */
export async function createResource(url, body, accessToken) {
  console.count('createResource');
  const { response, json, error } = await post(url, body, accessToken);

  return {
    response,
    data: json,
    error: error,
  };
}

/**
 * Updates a particular resource as detailed by the URL.
 *
 * @param {string} url - the URL to call
 * @param {Object} body - an object containing the properties to update
 * @param {string} etag - the property from the last get invocation to indicate that this resource has not changed
 * @param {string} accessToken - A valid access token of the 'Bearer' type
 *
 * @returns {Response} The response from the invocation
 */
export async function updateResource(url, body, etag, accessToken) {
  console.count('updateResource');
  const { response, json, error } = await patch(url, body, etag, accessToken);

  return {
    response,
    data: json,
    error: error,
  };
}

/**
 * Puts a particular resource as detailed by the URL.
 *
 * @param {string} url - the URL to call
 * @param {Object} body - an object containing the properties to put
 * @param {string} etag - the property from the last get invocation to indicate that this resource has not changed
 * @param {string} accessToken - A valid access token of the 'Bearer' type
 *
 * @returns {Response} The response from the invocation
 */
export async function putResource(url, body, etag, accessToken) {
  console.count('putResource');
  const { response, json, error } = await put(url, body, etag, accessToken);

  return {
    response,
    data: json,
    error: error,
  };
}

/**
 * Deletes a particular resource as detailed by the URL.
 *
 * @param {string} url - the URL to call
 * @param {string} etag - the property from the last get invocation to indicate that this resource has not changed
 * @param {string} accessToken - A valid access token of the 'Bearer' type
 *
 * @returns {Response} The response from the invocation
 */
export async function deleteResource(url, etag, accessToken) {
  console.count('deleteResource');
  const { response, json, error } = await _delete(url, etag, accessToken);

  return {
    response,
    data: json,
    error: error,
  };
}
