import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { ERROR_MESSAGE_PREAMBLE, ERROR_MESSAGE_SUPPORT } from 'utils/dictionary';
import styled from 'styled-components';

const ErrorCard = styled(Card)`
  border: 1px solid ${props => props.theme.colors.lightGrey};
  text-align: left;
  display: inline-block;
  margin: ${props => props.theme.spacingScale[6]};
  box-shadow: 0px 3px 6px ${props => props.theme.colors.shadow};
  &.MuiPaper-rounded {
    border-radius: ${props => props.theme.spacingScale[2]};
    width: 275px;
  }
  &:hover {
    background-color: ${props => props.theme.colors.lighterGrey};
    border: 1px solid ${props => props.theme.colors.primary};
  }
`;

const ErrorCardHeader = styled(CardHeader)`
  color: ${props => props.theme.colors.error};
  font-size: ${props => props.theme.typeScale[5]};
`;

const ErrorMessage = ({ errors, children }) => {
  const hasErrors = errors?.length > 0;
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <ErrorCard>
        <ErrorCardHeader title="Error" />
        <CardContent>
          {ERROR_MESSAGE_PREAMBLE}
          {hasErrors ? ':' : '.'}
          {hasErrors ? (
            <ul>
              {errors.map(message => (
                <li key={message}>{message}</li>
              ))}
            </ul>
          ) : null}
        </CardContent>
        <CardContent>{ERROR_MESSAGE_SUPPORT}</CardContent>
        <CardContent>{children}</CardContent>
      </ErrorCard>
    </Box>
  );
};

ErrorMessage.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};

ErrorMessage.defaultProps = {
  errors: [],
};

export default ErrorMessage;
