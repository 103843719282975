import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Container } from '@mui/material';

import Header from 'components/header/header';
import Footer from 'components/footer/footer';
import ContentFooter from 'components/contentFooter/contentFooter';
import styled from 'styled-components';
import { TenantDropdown } from 'components/tenantDropdown/TenantDropdown';
import { UserInfoContext } from "contexts/userInfoContext/userInfoContext";
import { TenantInfoContext } from 'contexts/tenantInfoContext/tenantInfoContext';
import { APPLICATION_DASHBOARD_PATH } from 'utils/dictionary';

const StyledDiv = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`;

const StyledContainer = styled(Container)`
  margin-top: 5em;
  margin-bottom: 5em;
  flex: 1 1 auto;
`;

const PlatformPage = ({ children }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const user = useContext(UserInfoContext);
  const tenants = useContext(TenantInfoContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (user?.data?.data && tenants) {
      setShowDropdown(
        tenants.length > 1 && 
        pathname === APPLICATION_DASHBOARD_PATH
      );
    }
  }, [user?.data?.data, tenants]);

  return (
    <>
      <StyledDiv>
        <Header />
        {showDropdown &&
          <TenantDropdown user={user.data?.data} tenants={tenants} />
        }
        <StyledContainer maxWidth={false}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            wrap="wrap"
            style={{ margin: '15px 0 0 0' }}
          >
            <Grid item xs={10}>
              {children}
            </Grid>
          </Grid>
        </StyledContainer>
        <div style={{ flex: '0 1 auto', marginTop: '-150px' }}>
          <ContentFooter />
          <Footer />
        </div>
      </StyledDiv>
    </>
  );
};

export default PlatformPage;
