import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import ProgressDisplay from 'containers/progressDisplay/progressDisplay';
import { trackException } from 'utils/trackingEvents';
import { useGetAllUserSubscriptions } from 'utils/hooks/useToCallApiEndpoints/useToCallApiEndpoints';
import {
  APPLICATION_DASHBOARD_PATH,
  PROFILE_PATH,
  SIGNOUT_PATH,
  APPLICATIONS_ERROR_MESSAGE,
  SERVICE_REQUEST_URL,
  CONTENT_FEEDBACK_PATH,
} from 'utils/dictionary';

import AppCardList from 'components/appCardList/appCardList';
import {
  CLIENT_SUPPORT_URL,
  SCREEN_READER_CSC_TOOLTIP,
  SCREEN_READER_LSR_TOOLTIP,
} from 'utils/dictionary';

import { Auth0Jwt } from 'components/auth';

import {
  CustomerServiceIcon,
  FeedbackIcon,
  ToolsIcon,
} from 'components/icons/icons';

import ServiceList from 'components/serviceList/serviceList';
import { useShowContentFeedback } from 'utils/hooks/displayComponent/useShowContentFeedback';
import { useShowLogRequest } from 'utils/hooks/displayComponent/useShowLogRequest';
import { TenantInfoContext } from 'contexts/tenantInfoContext/tenantInfoContext';

const filterRoutes = [
  APPLICATION_DASHBOARD_PATH.replace('/', ''),
  SIGNOUT_PATH.replace('/', ''),
  PROFILE_PATH.replace('/', ''),
];

const Container = styled.div`
  padding: 30px 0 10px 0;
  text-align: left;
`;

const AppsNotFoundMessageCritical = styled.p`
  color: ${props => props.theme.colors.backgroundLight};
  background-color: ${props => props.theme.colors.error};
  font-size: ${props => props.theme.typeScale[4]};
  margin-left: -${props => props.theme.spacingScale[5]};
  margin-right: -${props => props.theme.spacingScale[5]};
  padding: ${props => props.theme.spacingScale[3]} ${props => props.theme.spacingScale[5]};
`;

const AppsNotFoundMessage = styled.p`
  font-style: italic;
  font-size: ${props => props.theme.typeScale[4]};
`;

const StyledTitleContainer = styled.span`
  font-size: ${props => props.theme.typeScale[5]};
  color: ${props => props.theme.colors.darkPrimary};
`;

const StyledTitle = styled.h1`
  padding-top: ${props => props.theme.spacingScale[4]};
  padding-bottom: ${props => props.theme.spacingScale[2]};
  font-size: ${props => props.theme.typeScale[5]};
  font-weight: normal;
  margin: 0;
  border: 0;
`;

const goToApplication = (subscription, url) => {
  let features = '';
  if (sessionStorage.getItem('features')) {
    features = `&features=${JSON.parse(sessionStorage.getItem('features')).join(',')}`;
  } else if (window.location.search) {
    features = window.location.search;
  }

  const organizationAlias = window.location.pathname
    .split('/')
    .filter(o => o.length) // Remove empty elements
    .filter(r => !filterRoutes.includes(r))[0]; // Remove known application routes

  // App should pull alias from JWT in the worst case.. we should always have an alias in the url though
  const redirectUrl = organizationAlias
    ? `${url}/${organizationAlias}${features}`
    : `${url}${features}`;
  window.location.assign(redirectUrl);
};

// TODO: Need to refigure the Auth0Jwt call & object
const generateServiceRequestUrl = () => {
  let auth0Jwt = Auth0Jwt();
  let serviceRequestUrl = SERVICE_REQUEST_URL + '#' + auth0Jwt;

  return serviceRequestUrl;
};

const AppDashboard = () => {
  const { data: apps, errorMessage } = useGetAllUserSubscriptions();
  const serviceRequestUrl = generateServiceRequestUrl();
  const { showContentFeedback } = useShowContentFeedback();
  const { showLogRequest } = useShowLogRequest();
  const tenants = useContext(TenantInfoContext);

  const [services, setServices] = useState([
    {
      icon: <CustomerServiceIcon />,
      serviceName: 'Client Success Center',
      description: 'Submit billing forms and learn more about products',
      link: CLIENT_SUPPORT_URL,
      tooltip: SCREEN_READER_CSC_TOOLTIP,
    },
  ]);

  useEffect(() => {
    const addLsr = services.find(s => s.serviceName === 'Log Service Request') == null;
    if (showLogRequest && addLsr) {
      setServices([
        ...services,
        {
          icon: <ToolsIcon />,
          serviceName: 'Log Service Request',
          description: 'Request help or report an issue',
          link: serviceRequestUrl,
          tooltip: SCREEN_READER_LSR_TOOLTIP,
        },
      ]);
    }

    const addCfb = services.find(s => s.serviceName === 'Submit Content Feedback') == null;
    if (showContentFeedback && addCfb) {
      setServices([
        ...services,
        {
          icon: <FeedbackIcon />,
          serviceName: 'Submit Content Feedback',
          description: 'Share your ideas and content needs with our creators',
          link: CONTENT_FEEDBACK_PATH,
          internal: true
        }
      ]);
    }

    // Sort alphabetically
    services.sort((a, b) => a.serviceName.toLowerCase().localeCompare(b.serviceName.toLowerCase()));
  }, [
    showLogRequest,
    setServices,
    serviceRequestUrl,
    services,
    showContentFeedback,
  ]);

  if (!apps && !errorMessage) {
    return <ProgressDisplay message="Loading Applications" />;
  }

  if (errorMessage) {
    trackException({
      exception: new Error(errorMessage),
      name: 'myhealthwise_app_error_get_all_subscriptions',
      properties: {
        name: 'myhealthwise_app_error_get_all_subscriptions',
        url: window.location.href,
      },
    });

    return <AppsNotFoundMessageCritical>{APPLICATIONS_ERROR_MESSAGE}</AppsNotFoundMessageCritical>;
  }

  return (
    <div>
      <main tabIndex="-1">
        <Grid container style={{ marginTop: tenants.length > 1 ? '12em' : '' }}>
          <Grid item sm={12} md={8}>
            <StyledTitleContainer>
              <StyledTitle>Applications</StyledTitle>
            </StyledTitleContainer>
            {apps && apps.length ? (
              <>
                <Container>
                  <AppCardList applications={apps} subscriptionClick={goToApplication} />
                </Container>
              </>
            ) : (
              <AppsNotFoundMessage>{APPLICATIONS_ERROR_MESSAGE}</AppsNotFoundMessage>
            )}
          </Grid>

          <Grid item md={4}>
            <StyledTitleContainer>
              <StyledTitle>Services</StyledTitle>
            </StyledTitleContainer>
            {services && services.length && (
              <Container style={{ padding: 0 }}>
                <ServiceList services={services} />
              </Container>
            )}
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default AppDashboard;
