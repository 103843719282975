import styled from 'styled-components';

const StyledFooter = styled.div`
  height: 200px;
  background-image: url('/assets/content-footer.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
  margin-right: ${props => props.theme.spacingScale[4]};
  margin-bottom: ${props => props.theme.spacingScale[2]};
  z-index: -1;
`;
export const ContentFooter = () => {
  return <StyledFooter />;
};

export default ContentFooter;
