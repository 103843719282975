import { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
/**
 * ```js
 * const {
 *   // Auth state:
 *   error,
 *   isAuthenticated,
 *   isLoading,
 *   user,
 *   // Auth methods:
 *   getAccessTokenSilently,
 *   getAccessTokenWithPopup,
 *   getIdTokenClaims,
 *   loginWithRedirect,
 *   loginWithPopup,
 *   logout,
 * } = useAuth0<TUser>();
 * ```*/

// TODO: Instead of just returning the jwt, should we return the entire authState?
export const Auth0Jwt = () => {
  const authState = useAuth0();
  const [userJwt, setUserJwt] = useState('');

  useEffect(() => {
    const resolveIdTokenClaims = async () => {
      const token = await authState.getAccessTokenSilently();
      setUserJwt(token);
    };

    if (authState != null) {
      resolveIdTokenClaims();
    }
  }, [authState]);

  return userJwt;
};

export const Auth0State = () => {
  let authState = useAuth0();
  return authState;
};
