import { Auth0Jwt } from 'components/auth';
import { useState, useEffect } from 'react';
import { getTenantsForUser } from 'utils/api/tenants';
import jwt_decode from 'jwt-decode';
import * as EnvDictionary from 'utils/dictionary/env';

export const useGetTenantsForUser = () => {
    const [tenants, setTenants] = useState();
    const accessToken = Auth0Jwt();

    const formatTenants = (tenants) => {
        return Array.from({ length: tenants.length }, (_, idx) => {
            return {
                name: tenants[idx].name,
                alias: tenants[idx].alias,
                url:  `${EnvDictionary.REACT_APP_MYHEALTHWISE_API_BASE_URL}/${tenants[idx].alias}/applications`
            };
        });
    };

    useEffect(() => {
        const getTenants = async (userId) => {
            const data = await getTenantsForUser(userId, accessToken);
            const tenantList = formatTenants(data);
            return tenantList;
        }

        if (accessToken) {
            const jwt = jwt_decode(accessToken);
            getTenants(jwt.userId).then((tnts => {
                const currentTenant = tnts.find(t => t.alias === jwt.tempOrganizationAlias);

                if (currentTenant) {
                    setTenants([currentTenant, ...tnts.filter(t => t.alias !== jwt.tempOrganizationAlias)]);
                } else {
                    setTenants([tnts]);
                }
            }));
        }
    }, [accessToken]);

    return tenants;
}