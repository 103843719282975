/**
 *  The response object returned by all of the HTTP methods.
 *
 *  @typedef {Object} Response
 *  @property {Object} response - The raw response from the Fetch call
 *  @property {Object} json - The parsed JSON of the response
 *  @property {Object} error - The error state of the Fetch call, if needed
 */

/**
 * Make the http request using fetch(...).
 * If the fetch response is not 'ok' (200-299), the error is packed with the
 * status and message.
 *
 * @param {Object} request - a fully constructed Request object
 * @returns {Response} The response from the Fetch request
 *
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Request MDN Request API}
 */
export async function http(request) {
  let response = null;
  let json = null;
  let error = null;

  try {
    response = await fetch(request);
    if (response.ok) {
      // 204 generally has no content
      if (response.status !== 204) {
        json = await response.json();
      }
    } else {
      // Response was NOT ok. Capture the error as best as possible.
      let details;
      try {
        details = await response.json();
      } catch {
        // Doing nothing on purpose.
        // If the response doesn't have content, it'll throw an error on .json() */
      }

      // Pack the error message with the status and text
      error = {
        status: response.status,
        message: response.statusText,
        details,
      };
    }
  } catch (e) {
    // Network Error
    error = e;
  }

  return { response, json, error };
}

/**
 * Performs a GET operation on the provided url.
 *
 * @param {string} url - the URL to retrieve
 * @param {string} accessToken - A valid access token of the 'Bearer' type
 * @param {Object} headers - a map of optional headers to include on the request
 *
 * @returns {Response} The response of the GET operation
 */
export async function get(url, accessToken, headers) {
  var requestHeaders = {
    Accept: 'application/json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...headers,
  };

  return await http(new Request(url, { method: 'get', headers: new Headers(requestHeaders) }));
}

/**
 * Performs a POST operation on the provided url.
 *
 * @param {string} url - the URL to call
 * @param {Object} body - a map of properties to use as the body of the request
 * @param {string} accessToken - A valid access token of the 'Bearer' type
 * @param {Object} headers - a map of optional headers to include on the request
 *
 * @returns {Response} The response of the POST operation
 */
export async function post(url, body, accessToken, headers) {
  var requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...headers,
  };

  return await http(
    new Request(url, {
      method: 'post',
      headers: new Headers(requestHeaders),
      body: body ? JSON.stringify(body) : null,
    }),
  );
}

/**
 * Performs a PATCH operation on the provided url.
 *
 * @param {string} url - the URL to call
 * @param {Object} body - a map of properties to use as the body of the request
 * @param {string} etag - a property to indicate that the data being patched is the latest information
 * @param {string} accessToken - A valid access token of the 'Bearer' type
 * @param {Object} headers - a map of optional headers to include on the request
 *
 * @returns {Response} The response of the PATCH operation
 */
export async function patch(url, body, etag, accessToken, headers) {
  var requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...(etag && { 'If-Match': `${etag}` }),
    ...headers,
  };

  return await http(
    new Request(url, {
      method: 'patch',
      headers: new Headers(requestHeaders),
      body: JSON.stringify(body),
    }),
  );
}

/**
 * Performs a PUT operation on the provided url.
 *
 * @param {string} url - the URL to call
 * @param {Object} body - a map of properties to use as the body of the request
 * @param {string} etag - a property to indicate that the data being patched is the latest information
 * @param {string} accessToken - A valid access token of the 'Bearer' type
 * @param {Object} headers - a map of optional headers to include on the request
 *
 * @returns {Response} The response of the PUT operation
 */
export async function put(url, body, etag, accessToken, headers) {
  var requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...(etag && { 'If-Match': `${etag}` }),
    ...headers,
  };

  return await http(
    new Request(url, {
      method: 'put',
      headers: new Headers(requestHeaders),
      body: JSON.stringify(body),
    }),
  );
}

/**
 * Performs a DELETE operation on the provided url.
 *
 * @param {string} url - the URL to call
 * @param {string} etag - a property to indicate that the data being patched is the latest information
 * @param {string} accessToken - A valid access token of the 'Bearer' type
 * @param {Object} headers - a map of optional headers to include on the request
 *
 * @returns {Response} The response of the DELETE operation
 */
export async function _delete(url, etag, accessToken, headers) {
  var requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...(etag && { 'If-Match': `${etag}` }),
    ...headers,
  };

  return await http(
    new Request(url, {
      method: 'delete',
      headers: new Headers(requestHeaders),
    }),
  );
}
