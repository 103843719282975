import React from 'react';
import PlatformPage from 'pages/platformPage/platformPage';

import AppDashboard from './appDashboard';

/**
 * Routes needed to provide access to the Application Dashboard
 */
export const AppDashboardRoutes = () => {
    return (
        <PlatformPage>
            <AppDashboard />
        </PlatformPage>
    );
};

export default AppDashboardRoutes;
