import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Grid, IconButton } from '@mui/material';

import { UserInformationPanel } from './userInformationPanel';
import RoleDisplayPanel from 'components/roleDisplayPanel/roleDisplayPanel';
import { useProfile } from 'utils/hooks/useProfile/useProfile';
import { ArrowBackIcon } from 'components/icons/icons';

import { BACK_TO_PREVIOUS_PAGE, MY_PROFILE, MY_PROFILE_TEXT } from 'utils/dictionary';

const GridWrapper = styled.div`
  padding: 0 0 0 0;
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  padding: 0 10px 0 0;
  color: ${props => props.theme.colors.primary};
`;
const StyledParagraph = styled.p`
  font-size: ${props => props.theme.typeScale[3]};
  color: ${props => props.theme.colors.primary};
`;

const StyledHeaderParagraph = styled.p`
  font-size: ${props => props.theme.typeScale[5]};
  color: ${props => props.theme.colors.primary};
`;

const StyledTextParagraph = styled.p`
  font-size: ${props => props.theme.typeScale[2]};
  padding: 0 0 10px 0;
  color: ${props => props.theme.colors.neutralGrey};
`;

const StyledPrevPageButton = styled(IconButton)`
  font-size: ${props => props.theme.typeScale[2]};
  color: ${props => props.theme.colors.primary};
  margin-left: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  &:hover {
    background-color: #f4f4f4;
  }
`;

const Profile = props => {
  const { data: profileResponse } = useProfile();
  const userProfile = profileResponse?.data;
  const roles = userProfile?.roles ?? [];
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <GridWrapper>
      <StyledParagraph>
        <StyledPrevPageButton onClick={goBack}>
          <StyledArrowBackIcon />
          {BACK_TO_PREVIOUS_PAGE}
        </StyledPrevPageButton>
      </StyledParagraph>
      <StyledHeaderParagraph>{MY_PROFILE}</StyledHeaderParagraph>
      <StyledTextParagraph>{MY_PROFILE_TEXT}</StyledTextParagraph>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <UserInformationPanel profile={userProfile} />
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={8}>
          <RoleDisplayPanel roles={roles} {...props} />
        </Grid>
      </Grid>
    </GridWrapper>
  );
};

export default Profile;
