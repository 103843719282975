import React from 'react';
import { Button as MaterialButton, IconButton as MaterialIconButton, Link } from '@mui/material';

import { DEFAULT_BTN_COLOR_CONFIRM_GREEN } from 'utils/dictionary/overview';
import style from './button.module.scss';
import styled from 'styled-components';

export const WhiteButton = styled(MaterialButton)`
  border-radius: 8px 8px 8px 8px;
  border: 1px solid ${props => props.theme.colors.lightGrey};
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.darkestGrey};
  font-size: ${props => props.theme.typeScale[2]};
  &.MuiButton-root {
    padding: 5px 15px;
    margin: 0;
  }
`;

export const PrimaryButton = styled(MaterialButton)`
  border-radius: 8px 8px 8px 8px;
  border: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.typeScale[2]};
`;

export const ButtonLink = styled(Link)`
  font-size: ${props => props.theme.typeScale[2]};
  margin-top: auto;
  margin-left: 5px;
  margin-bottom: 5px;
`;

const cancelButtonStyle = {
  border: '1px solid #F4F4F4',
};

export const CancelButton = ({ buttonText, handleClick, className }) => {
  return (
    <MaterialButton
      onClick={handleClick}
      className={`${style.buttonCancel} ${style.button} ${className}`}
    >
      {buttonText}
    </MaterialButton>
  );
};

export const GeneralCancelButton = ({ handleClick, ...props }) => {
  return (
    <MaterialButton
      className={`${style.button} ${style.generalCancelButton}`}
      variant="outlined"
      onClick={handleClick}
      {...props}
      style={cancelButtonStyle}
    >
      Cancel
    </MaterialButton>
  );
};

export const ConfirmationModalButton = ({
  handleClick,
  buttonText,
  backgroundColor,
  className,
  ...props
}) => {
  let bgColor =
    backgroundColor === DEFAULT_BTN_COLOR_CONFIRM_GREEN
      ? style.backgroundGreen
      : style.backgroundRed;
  return (
    <MaterialButton
      className={`${style.button} ${style.confirmationModalButton} ${bgColor} ${className}`}
      variant="outlined"
      onClick={handleClick}
      {...props}
    >
      {buttonText}
    </MaterialButton>
  );
};

export const ConfirmationModalIconEndButton = ({
  handleClick,
  buttonText,
  backgroundColor,
  className,
  endIcon,
  ...props
}) => {
  let bgColor =
    backgroundColor === DEFAULT_BTN_COLOR_CONFIRM_GREEN
      ? style.backgroundGreen
      : style.backgroundRed;
  return (
    <MaterialIconButton
      className={`${style.button} ${style.confirmationModalButton} ${bgColor} ${className}`}
      variant="outlined"
      onClick={handleClick}
      {...props}
    >
      {buttonText}
      {endIcon}
    </MaterialIconButton>
  );
};

export const GreenOutlinedButtonLink = ({ buttonText, handleClick }) => {
  return (
    <MaterialButton
      onClick={handleClick}
      className={`${style.button} ${style.outlineButtonGreen}`}
      variant="outlined"
    >
      {buttonText}
    </MaterialButton>
  );
};

export const GreenFormSubmitButton = props => {
  const { isValid, dirty, values, actions, handleSubmit, buttonText } = props;

  return (
    <>
      <MaterialButton
        className={`${style.button} ${style.greenButton}`}
        type="submit"
        variant="contained"
        onClick={() => handleSubmit(values, actions)}
        disabled={!(isValid && dirty)}
      >
        {buttonText}
      </MaterialButton>
    </>
  );
};
