import React from 'react';

import PlatformPage from 'pages/platformPage/platformPage';

import Subscriptions from './subscriptions';

/**
 * Routes needed to provide Subscriptions access.
 */
export const SubscriptionsRoutes = () => {
  return (
    <PlatformPage>
      <Subscriptions />
    </PlatformPage>
  );
};

export default SubscriptionsRoutes;
