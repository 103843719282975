import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';

const SignIn = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const queryParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let inviteTicket = queryParams.invitation;
    let organization = queryParams.organization;

    // If this is a user invite, redirect to login with invite ticket,
    // otherwise do nothing.
    if (inviteTicket && organization) {
      loginWithRedirect({
        authorizationParams: {
          organization: organization,
          invitation: inviteTicket,
        },
      });
    }
    // When a password reset occurs, use the organization so they only have to
    // enter their email/new password
    else if (organization) {
      loginWithRedirect({
        authorizationParams: {
          organization: organization,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default SignIn;
