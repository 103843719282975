import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import styled from 'styled-components';

const StyledListItem = styled(ListItem)`
    padding: ${props => props.theme.spacingScale[1]} 0px;
`;

const StyledTextContainer = styled.div`
    display: block;
`;

const StyledListItemIcon = styled(ListItemIcon)`
    min-width: 35px;
    padding-bottom: 15px;
    color: black;
`;

const StyledListItemText = styled.a`
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
`;

// Following tooltip style from footer
const StyledTooltip = styled(props => <Tooltip classes={{ popper: props.className }} {...props} />)`
  & .MuiTooltip-tooltip {
    font-size: ${props => props.theme.typeScale[1]};
    color: ${props => props.theme.colors.primaryTextOnDark};
    background-color: ${props => props.theme.colors.secondaryText};
    width: '200px';
    padding: '6px';
  }
`;

const Service = ({icon, name, description, link, internal, tooltip}) => {
    const navigate = useNavigate();
    const { search } = useLocation();

    const goTo = (e) => {
        // Handle tab to/enter key for a11y purposes
        if (e.type === "keyup" && e.key !== "Enter")
            return;

        navigate({
            pathname: link,
            search: search
        });
    }
    return (
        <StyledListItem>
            <StyledListItemIcon>
                {icon}
            </StyledListItemIcon>
            <StyledTextContainer>
                <StyledTooltip title={tooltip} arrow placement="right-start">
                    {internal
                        ? <StyledListItemText onClick={goTo} onKeyUp={goTo} tabIndex="0">{name}</StyledListItemText>
                        : <StyledListItemText href={link} target="_blank">{name}</StyledListItemText>
                    }
                </StyledTooltip>
                <ListItemText secondary={description} />
            </StyledTextContainer>
        </StyledListItem>
    );
}

export default Service;