import { useState, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import {
  ERROR_MESSAGE_UNAUTHORIZED,
  ERROR_MESSAGE_INTERNAL_SERVER_ERROR,
  ERROR_MESSAGE_NETWORK_ERROR,
} from 'utils/dictionary';
import { getUserSubscriptions, getAllUserSubscriptions } from 'utils/api/subscriptions';

import { Auth0State, Auth0Jwt } from 'components/auth';

const dataDefault = undefined;
const errorDefault = '';

const setDataOrErrorMessage = (response, data, error, setData, setErrorMessage) => {
  switch (response?.status) {
    case 200: // Ok
    case 201: // Created
      setData(data);
      setErrorMessage(errorDefault);
      break;
    case 204: // NoContent
      setData(dataDefault);
      setErrorMessage(errorDefault);
      break;
    case 401:
      setData(dataDefault);
      setErrorMessage(ERROR_MESSAGE_UNAUTHORIZED);
      break;
    case 500:
      setData(dataDefault);
      setErrorMessage(ERROR_MESSAGE_INTERNAL_SERVER_ERROR);
      break;
    default:
      data ? setData(data) : setData(dataDefault);
      error ? setErrorMessage(ERROR_MESSAGE_NETWORK_ERROR) : setErrorMessage(errorDefault);
      break;
  }
};

const nameSort = (a, b) => {
  const aUpper = a.trim().toUpperCase();
  const bUpper = b.trim().toUpperCase();

  if (aUpper === bUpper) return 0;
  return aUpper < bUpper ? -1 : 1;
};

const nameSortByOrganizationsThenSubscriptions = (a, b) => {
  const orgSort = nameSort(a.organizationName, b.organizationName);

  if (orgSort !== 0) {
    return orgSort;
  }

  return nameSort(a.name, b.name);
};

/**
 * Retrieve all subscriptions that the user has access to and contain the application clientId.
 *
 * This is leveraged when an application (like ProvTool/Reporting) is checking to see if their
 * access tokens have access to the requested subscription.
 */
export const useGetUserSubscriptions = clientId => {
  const authState = Auth0State();
  const accessToken = Auth0Jwt();
  useErrorHandler(authState?.error);

  const [data, setData] = useState(dataDefault);
  const [errorMessage, setErrorMessage] = useState(errorDefault);

  const sortAndSetData = data => {
    if (data?.subscriptions?.length) {
      data.subscriptions = data.subscriptions.sort(nameSortByOrganizationsThenSubscriptions);
    }
    setData(data);
  };

  const useEffectAuthState = {
    isAuthenticated: authState?.isAuthenticated,
    accessToken: accessToken,
  };

  useEffect(() => {
    const response = async () => {
      const { response, data, error } = await getUserSubscriptions(
        clientId,
        useEffectAuthState.accessToken,
      );

      setDataOrErrorMessage(response, data, error, sortAndSetData, setErrorMessage);
    };

    if (useEffectAuthState.isAuthenticated && useEffectAuthState.accessToken) {
      response();
    }
  }, [useEffectAuthState.isAuthenticated, useEffectAuthState.accessToken, clientId]);

  return { data, errorMessage };
};

export const useGetAllUserSubscriptions = () => {
  const authState = Auth0State();
  const accessToken = Auth0Jwt();
  useErrorHandler(authState?.error);
  const [data, setData] = useState(dataDefault);
  const [errorMessage, setErrorMessage] = useState(errorDefault);

  const sortAndSetData = data => {
    if (data?.length) {
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item?.subscriptions?.length) {
          item.subscriptions = item.subscriptions.sort(nameSortByOrganizationsThenSubscriptions);
        }
      }
    }
    setData(data);
  };

  const useEffectAuthState = {
    isAuthenticated: authState?.isAuthenticated,
    accessToken: accessToken,
  };

  useEffect(() => {
    const response = async () => {
      const { response, data, error } = await getAllUserSubscriptions(
        useEffectAuthState.accessToken,
      );
      // Use to force a delay while loading the subscriptions/applications
      //await new Promise(resolve => setTimeout(resolve, 5000));
      setDataOrErrorMessage(response, data, error, sortAndSetData, setErrorMessage);
    };
    if (useEffectAuthState.isAuthenticated && useEffectAuthState.accessToken) {
      response();
    }
  }, [useEffectAuthState.isAuthenticated, useEffectAuthState.accessToken]);

  return { data, errorMessage };
};
