import React, { useState } from 'react';
import { DefaultApplicationIcon, ExpandMoreIcon } from 'components/icons/icons';
import { Button, Card, CardContent, Menu, MenuItem, Typography } from '@mui/material';
import { SCREEN_READER_TOOLTIP_TEXT } from 'utils/dictionary';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  border: 1px solid ${props => props.theme.colors.lightGrey};
  text-align: center;
  display: inline-block;
  margin-right: ${props => props.theme.spacingScale[6]};
  margin-bottom: ${props => props.theme.spacingScale[6]};
  box-shadow: 0px 3px 6px ${props => props.theme.colors.shadow};
  &.MuiPaper-rounded {
    border-radius: ${props => props.theme.spacingScale[2]};
    width: 275px;
    height: 310px;
  }
  &:hover {
    background-color: ${props => props.theme.colors.lighterGrey};
    border: 1px solid ${props => props.theme.colors.primary};
  }
`;

const StyledCardContent = styled(CardContent)`
  padding: 1.75em;
`;

const StyledButton = styled(Button)`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  text-transform: capitalize;
  border-radius: ${props => props.theme.spacingScale[1]};
  width: 200px;
  height: 40px;
  font-size: ${props => props.theme.typeScale[2]};
  box-shadow: none;
  border: none;
  &:focus {
    outline: 2px dotted ${props => props.theme.colors.darkestGrey};
    outline-offset: 2px;
  }
  &:hover {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  }
`;

const StyledCardHeaderText = styled(Typography)`
  padding-bottom: 0.625rem;
  &.MuiTypography-root {
    color: ${props => props.theme.colors.darkestGrey};
    font-size: ${props => props.theme.typeScale[4]};
  }
`;

const StyledCardContentText = styled(Typography)`
  padding-bottom: ${props => props.theme.spacingScale[3]};
  text-align: left;
  line-height: ${props => props.theme.typeScale[4]};
  &.MuiTypography-root {
    color: ${props => props.theme.colors.neutralGrey};
    font-size: ${props => props.theme.typeScale[2]};
    padding-left: 10px;
  }
`;

const StyledIcon = styled.div`
  vertical-align: middle;
  width: auto;
  height: 6rem;
  padding-bottom: 1rem;
`;

const OrganizationMenuItem = styled(MenuItem)`
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.typeScale[2]};
  padding-right: 0px;
  white-space: unset;
  word-break: break-word;
`;

// TODO Lift Paperprops into styled component
const SubscriptionMenu = styled(Menu)``;

const selectSubscription = (subscription, url, subscriptionClick) => {
  subscriptionClick(subscription, url);
};

// TODO iconLink is misnamed. It represents the base64 data stream of an svg icon.
const AppCard = ({
  name,
  description,
  url,
  subscriptions,
  iconLink,
  iconAlt,
  iconSvg,
  subscriptionClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  if (!subscriptions || subscriptions.length === 0) {
    return null;
  }

  const addSubscriptionMap = (event, value) => {
    selectSubscription(value, url, subscriptionClick);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledCard variant="outlined">
      <StyledCardContent>
        <StyledIcon>
          {iconLink ? (
            <img src={`data:image/svg+xml;base64,${iconLink}`} alt="" />
          ) : iconSvg ? (
            <img src={iconSvg} alt="" />
          ) : (
            <DefaultApplicationIcon />
          )}
        </StyledIcon>

        <StyledCardHeaderText component="h2">{name}</StyledCardHeaderText>
        <StyledCardContentText>{description}</StyledCardContentText>
        {subscriptions.length > 1 ? (
          <>
            <StyledButton
              disableRipple
              onClick={handleClick}
              aria-label={'Open to access ' + name + ' for multiple organizations'}
              aria-haspopup={true}
            >
              Select to Open <ExpandMoreIcon />
            </StyledButton>
            <SubscriptionMenu
              id="sub-menu"
              keepMounted
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              paper={{
                style: {
                  boxShadow: `0px 3px 6px ${props => props.theme.colors.shadow}`,
                  borderRadius: '4px',
                  color: `${props => props.theme.colors.primary}`,
                  width: '200px',
                },
              }}
            >
              {subscriptions.map(subscription => {
                const organizationName = subscription.organizationName;
                return (
                  <OrganizationMenuItem
                    key={subscription.id}
                    value={subscription.name}
                    onClick={e => addSubscriptionMap(e, subscription)}
                    aria-label={'Open ' + name + ' for ' + organizationName}
                  >
                    {organizationName}
                  </OrganizationMenuItem>
                );
              })}
            </SubscriptionMenu>
          </>
        ) : (
          <>
            {name === 'Log Service Request' ? (
              <StyledButton
                disableRipple
                onClick={e => selectSubscription(subscriptions[0], url, subscriptionClick)}
                aria-label={'Open ' + name + '. ' + SCREEN_READER_TOOLTIP_TEXT}
              >
                Open
              </StyledButton>
            ) : (
              <StyledButton
                disableRipple
                onClick={e => selectSubscription(subscriptions[0], url, subscriptionClick)}
                aria-label={'Open ' + name}
              >
                Open
              </StyledButton>
            )}
          </>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

export default AppCard;
