import React from 'react';
import { useProfile } from 'utils/hooks/useProfile/useProfile';

const UserInfoContext = React.createContext();

const UserInfoProvider = ({ children }) => {
  const userInfo = useProfile();

  return <UserInfoContext.Provider value={userInfo}>{children}</UserInfoContext.Provider>;
};

export { UserInfoContext, UserInfoProvider };
